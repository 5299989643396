import { useContext } from 'react';
import { useEffect, useRef, useState } from 'react';
import { TimelineContext } from './index';
import { StyledTimeline } from './styled';
import { linkS3Video, decryptPath, STORAGE, funcThumbnailIMG } from 'utils';
import { PlayIcon } from 'images';
import { useLayoutEffect } from 'react';
import { ImgCustom } from 'components';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import { newPathUser } from 'constants/layout/constant';
import { isMobile, isSafari, isTablet } from 'react-device-detect';
import ReactHlsPlayer from 'react-hls-player';
import { useSelector, useDispatch } from 'react-redux';
import { logUserProfile } from 'store/actions/myPage';
import Storage from 'utils/storage';
import useDisableContextMenu from 'hooks/useDisableContextMenu';
import { VideoInList } from './VideoInList';
import { VideoTag } from './VideoTag';
import { getIDFavor } from 'store/actions/usersNew';
import styled, { css } from 'styled-components';
import useCheckFullVideo from 'hooks/v2/useCheckFullVideo';

const WrapperHideIconVideo = styled.div`
  width: 100%;
  //height: 100%;
  position: relative;
  padding-top: 56.25%;
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  video::-webkit-media-controls-overlay-play-button {
    ${({ isFull = false }) =>
      isFull
        ? css`
            display: block;
          `
        : css`
            display: none;
          `};
  }

  *::-webkit-media-controls-start-playback-button {
    ${({ isFull = false }) =>
      isFull
        ? css`
            display: block;
          `
        : css`
            display: none !important;
            -webkit-appearance: none;
          `};
  }
`;
const delay = 15000;
export const Card = () => {
  const isAuth = Storage.has(STORAGE.USER_ACCESS_TOKEN);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, item, flgPause, page, itemParent } = useContext(TimelineContext);
  // const { runTimeline, handleRunTimeLine } = useContext(TimelineWrapperContext2);
  const [run, setRun] = useState(false);
  const { dataUser } = useSelector((state) => state.users);
  const [play, setPlay] = useState(true);
  const [index, setIndex] = useState(0);
  const [playVideo, setPlayVideo] = useState({});
  const [showButton, setShowButton] = useState(true);
  const timeoutRef = useRef(null);
  const refVideo = useRef();
  const buttonRef = useRef();
  const [isPlaying, setIsPlaying] = useState(false);
  const [controlCustom, setControlCustom] = useState(false);
  const [isFullScreen, setIsFullScreen] = useCheckFullVideo(refVideo);
  useDisableContextMenu(refVideo);
  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };
  const tmpData = item?.type !== 2 ? data.filter((item) => item?.type !== 4) : data;
  const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
  useEffect(() => {
    if (run) {
      resetTimeout();
      timeoutRef.current = setTimeout(() => {
        setIndex((prev) => (prev === tmpData.length - 1 ? 0 : prev + 1));
      }, delay);

      return () => {
        resetTimeout();
      };
    }
  }, [index, run]);

  useEffect(() => {
    if (flgPause) {
      refVideo?.current?.pause();
    }
  }, [flgPause]);

  const refCard = useRef();
  const scrollRoot = () => {
    setRun(
      refCard.current?.getBoundingClientRect()?.top < 400 &&
        refCard.current?.getBoundingClientRect()?.top > -300
    );
    if (
      !Boolean(
        refCard.current?.getBoundingClientRect()?.top < 400 &&
          refCard.current?.getBoundingClientRect()?.top > -300
      )
    ) {
      setShowButton(true);
      setPlay(true);
      setPlayVideo({
        controls: false,
      });
      refVideo?.current?.pause();
    }
  };
  useLayoutEffect(() => {
    setRun(
      refCard.current?.getBoundingClientRect()?.top < 400 &&
        refCard.current?.getBoundingClientRect()?.top > -300
    );
  }, []);
  useLayoutEffect(() => {
    document.querySelector('#root').addEventListener('scroll', scrollRoot);
    return () => {
      document.querySelector('#root').removeEventListener('scroll', scrollRoot);
    };
  }, []);

  const handleNavigate = (type, post_id, itemParent) => {
    if (!isAuth) {
      dispatch(logUserProfile());
    } else {
      dispatch(getIDFavor({ id: itemParent?.id, page: page, tab: 1 }));
      if (type === 2) {
        navigate(`${newPathUser}post/${post_id}`);
      } else {
        navigate(`${newPathUser}post/${post_id}`);
      }
    }
  };

  const isMiniUrl =
    (item?.is_followed === 0 && item?.follow_flag === 1 && item?.user_id !== dataUser?.id) ||
    (item?.is_media_display === 0 && item?.type !== 2);

  return (
    <>
      {/* case 1 img or video */}
      {tmpData?.length === 1 && (
        <div
          // className={`one-media-card ${
          //   isMobile && showButton ? 'show-play-icon' : 'unshow-play-icon'
          // }`}
          className='one-media-card'
          ref={refCard}
          style={{
            display: 'flex',
            width: '100%',
            position: 'relative',
            padding: '0 16px',
            gap: '2px',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '5px'
          }}>
          {tmpData[0]?.type === 1 || tmpData[0]?.type === 4 ? ( // img
            <StyledTimeline.PostBlurImg1
              url={`${decryptPath(tmpData[0].url, { isMini: true })}`}
              height={360}>
              <div className='bg-img'></div>
              <ImgCustom
                onClick={() => {
                  handleNavigate(item?.type, item?.id, itemParent);
                }}
                isMini={isMiniUrl}
                screen={'post_640_auto_pc'}
                style={{
                  height: '100%',
                  width: '100%',
                  margin: 'auto',
                  top: '0',
                  left: '0',
                  right: '0',
                  bottom: '0',
                  objectFit: 'contain',
                  position: 'absolute',
                  zIndex: '10',
                  background: 'transparent',
                }}
                src={tmpData[0].url}
                alt=''
              />
            </StyledTimeline.PostBlurImg1>
          ) : (
            // video
            <>
              {decryptPath(tmpData[0]?.url) !== 'NULL' && typeof decryptPath(tmpData[0]?.url) === 'string' &&
              decryptPath(tmpData[0]?.url)?.indexOf('.m3u8') === -1 ? (
                <VideoTag
                  isMini={isMiniUrl && isFirefox}
                  tmpData={tmpData}
                  item={item}
                  handleNavigate={() => handleNavigate(item?.type, item?.id, itemParent)}
                />
              ) : (
                <WrapperHideIconVideo
                  onClick={() => {
                    if (!isPlaying) {
                      const video = refVideo.current;
                      if (video) {
                        video.controls = !video.controls;
                      }
                      setControlCustom(false);
                      handleNavigate(item?.type, item?.id, itemParent);
                    }
                  }}>
                  {!isPlaying && controlCustom && (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        handleNavigate(item?.type, item?.id, itemParent);
                      }}
                      style={{
                        position: 'absolute',
                        width: '100%',
                        height: '70%',
                        zIndex: 32,
                        top: 0,
                      }}></div>
                  )}
                  <ReactHlsPlayer
                    preload='none'
                    style={{ objectFit: 'contain', background: '#000', zIndex: 30 }}
                    className='video-16-9'
                    playerRef={refVideo}
                    src={`${linkS3Video}${decryptPath(tmpData[0]?.url) + '#t=0.1'}`}
                    autoPlay={false}
                    controls={isFullScreen ? true : controlCustom}
                    controlsList='nodownload noremoteplayback noplaybackrate foobar'
                    disablePictureInPicture
                    onPause={(e) => {
                      if (refVideo.current.seeking) return;
                      refVideo.current.pause();
                      setControlCustom(true);
                      setIsPlaying(false);
                    }}
                    onPlay={() => {
                      refVideo.current.play();
                      setIsPlaying(true);
                      setControlCustom(true);
                    }}
                    width='100%'
                    height='100%'
                    playsInline={true}
                  />
                  {!isFullScreen && (
                    <StyledTimeline.ButtonPlay
                      widthButton='56px'
                      heightButton='56px'
                      onClick={(e) => {
                        e.stopPropagation();
                        if (isPlaying) {
                          setIsPlaying(false);
                          setControlCustom(true);
                          refVideo.current.pause();
                        } else {
                          setIsPlaying(true);
                          setControlCustom(true);
                          refVideo.current.play();
                        }
                      }}>
                      {!isPlaying && (
                        <PlayIcon
                          style={{
                            filter: 'drop-shadow(0px 0px 0.5px #000)',
                          }}
                        />
                      )}
                    </StyledTimeline.ButtonPlay>
                  )}
                </WrapperHideIconVideo>
              )}
            </>
          )}
        </div>
      )}
      {/* case 2 img or video // case 3 img or video */}
      {tmpData?.length === 2 && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            padding: '0 16px',
            gap: '2px',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            marginBottom: '5px'
          }}
          className='two-media-card'>
          <VideoInList
            isMini={isMiniUrl && isFirefox}
            tmpData={tmpData}
            item={item}
            isMiniUrl={isMiniUrl}
            handleNavigate={() => handleNavigate(item?.type, item?.id, itemParent)}
          />
          <div
            className='position-relative w-100'
            style={{ width: '319px' }}
            onClick={() => {
              handleNavigate(item?.type, item?.id, itemParent);
            }}>
            {tmpData[1]?.type === 1 ? (
              <StyledTimeline.PostBlurImg
                url={`${decryptPath(tmpData[1].url, { isMini: true })}`}
                height={319}>
                <div className='bg-img'></div>
                <ImgCustom
                  isMini={isMiniUrl}
                  screen={'post_319_auto_pc'}
                  src={tmpData[1]?.url}
                  alt=''
                  style={{
                    objectFit: 'contain',
                    position: 'absolute',
                    zIndex: '10',
                    background: 'transparent',
                    width: '100%',
                    // height: '319px',
                  }}
                />
              </StyledTimeline.PostBlurImg>
            ) : (
              <>
                <StyledTimeline.PostBlurImg
                  url={`${funcThumbnailIMG(
                    decryptPath(tmpData[1].url, {
                      isMini: true,
                    })
                  )}`}
                  height={319}>
                  <div className='bg-img'></div>
                  <ImgCustom
                    isMini={isMiniUrl}
                    screen={'post_319_auto_pc'}
                    src={funcThumbnailIMG(decryptPath(tmpData[1]?.url))}
                    alt=''
                    style={{
                      objectFit: 'contain',
                      position: 'absolute',
                      zIndex: '10',
                      background: 'transparent',
                      width: '100%',
                      // height: '319px',
                    }}
                  />
                </StyledTimeline.PostBlurImg>
                {/* <video
                  controlsList='nodownload'
                  width='100%'
                  src={linkS3Video + decryptPath(tmpData[1]?.url) + '#t=0.1'}></video> */}
                <StyledTimeline.ButtonPlay>
                  <PlayIcon />
                </StyledTimeline.ButtonPlay>
              </>
            )}
          </div>
        </div>
      )}
      {/* case 3 img or video // case 3 img or video */}
      {tmpData?.length === 3 && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            padding: '0 16px',
            gap: '2px',
            marginBottom: '5px'
          }}
          className={`three-media-card ${isMobile ? 'isMobile' : ''}`}>
          <VideoInList
            isMini={isMiniUrl && isFirefox}
            tmpData={tmpData}
            item={item}
            isMiniUrl={isMiniUrl}
            handleNavigate={() => handleNavigate(item?.type, item?.id, itemParent)}
            typeShow='three_items'
          />

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '2px',
              width: '100%',
            }}>
            <div
              className='position-relative w-100'
              style={{ height: `${isMobile ? '171px' : '319px'}` }}
              onClick={() => {
                handleNavigate(item?.type, item?.id, itemParent);
              }}>
              {tmpData[1]?.type === 1 ? (
                <StyledTimeline.PostBlurImg
                  url={`${decryptPath(tmpData[1].url, { isMini: true })}`}
                  height={`${isMobile ? 171 : 319}`}
                  width={`${isMobile ? 185 : 319}`}>
                  <div className='bg-img'></div>
                  <ImgCustom
                    className='secondary-img'
                    isMini={isMiniUrl}
                    screen={'post_319_auto_pc'}
                    style={{
                      height: '100%',
                      width: '100%',
                      position: 'absolute',
                      objectFit: 'contain',
                      zIndex: '10',
                      background: 'transparent',
                    }}
                    src={tmpData[1]?.url}
                    alt=''
                  />
                </StyledTimeline.PostBlurImg>
              ) : (
                <div className='d-flex align-items-center h-100'>
                  <StyledTimeline.PostBlurImg
                    url={`${funcThumbnailIMG(
                      decryptPath(tmpData[1].url, {
                        isMini: true,
                      })
                    )}`}
                    height={`${isMobile ? 171 : 319}`}
                    width={`${isMobile ? 185 : 319}`}>
                    <div className='bg-img'></div>
                    <ImgCustom
                      className='secondary-img'
                      isMini={isMiniUrl}
                      screen={'post_319_auto_pc'}
                      style={{
                        height: '100%',
                        width: '100%',
                        objectFit: 'contain',
                        position: 'absolute',
                        zIndex: '10',
                        background: 'transparent',
                      }}
                      src={funcThumbnailIMG(decryptPath(tmpData[1]?.url))}
                      alt=''
                    />
                  </StyledTimeline.PostBlurImg>
                  {/* <video
                    controlsList='nodownload'
                    width='100%'
                    height='319px'
                    src={linkS3Video + decryptPath(tmpData[1]?.url) + '#t=0.1'}></video> */}
                  <StyledTimeline.ButtonPlay>
                    <PlayIcon />
                  </StyledTimeline.ButtonPlay>
                </div>
              )}
            </div>
            <div
              className='position-relative w-100'
              onClick={() => {
                handleNavigate(item?.type, item?.id, itemParent);
              }}>
              {tmpData[2]?.type === 1 ? (
                <StyledTimeline.PostBlurImg
                  url={`${decryptPath(tmpData[2].url, { isMini: true })}`}
                  height={`${isMobile ? 171 : 319}`}
                  width={`${isMobile ? 185 : 319}`}>
                  <div className='bg-img'></div>
                  <ImgCustom
                    className='secondary-img'
                    isMini={isMiniUrl}
                    screen={'post_319_auto_pc'}
                    style={{
                      height: '100%',
                      width: '100%',
                      objectFit: 'contain',
                      position: 'absolute',
                      zIndex: '10',
                      background: 'transparent',
                    }}
                    src={tmpData[2]?.url}
                    alt=''
                  />
                </StyledTimeline.PostBlurImg>
              ) : (
                <div className='d-flex align-items-center h-100'>
                  <StyledTimeline.PostBlurImg
                    url={`${funcThumbnailIMG(
                      decryptPath(tmpData[2].url, {
                        isMini: true,
                      })
                    )}`}
                    height={`${isMobile ? 171 : 319}`}
                    width={`${isMobile ? 185 : 319}`}>
                    <div className='bg-img'></div>
                    <ImgCustom
                      className='secondary-img'
                      isMini={isMiniUrl}
                      screen={'post_319_auto_pc'}
                      style={{
                        height: `${isMobile ? '171px' : '319px'}`,
                        width: `${isMobile ? '100%' : '319px'}`,
                        objectFit: 'contain',
                        position: 'absolute',
                        zIndex: '10',
                        background: 'transparent',
                      }}
                      src={funcThumbnailIMG(decryptPath(tmpData[2]?.url))}
                      alt=''
                    />
                  </StyledTimeline.PostBlurImg>
                  <StyledTimeline.ButtonPlay>
                    <PlayIcon />
                  </StyledTimeline.ButtonPlay>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {/* case >= 4 img or video */}
      {tmpData?.length >= 4 && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            padding: '0 16px',
            gap: '2px',
            marginBottom: '5px'
          }}
          className='four-media-card'>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '2px', width: '100%' }}>
            <VideoInList
              isMini={isMiniUrl && isFirefox}
              tmpData={tmpData}
              item={item}
              isMiniUrl={isMiniUrl}
              handleNavigate={() => handleNavigate(item?.type, item?.id, itemParent)}
            />
            <div
              className='position-relative w-100'
              onClick={() => {
                handleNavigate(item?.type, item?.id, itemParent);
              }}>
              {tmpData[2]?.type === 1 ? (
                <StyledTimeline.PostBlurImg
                  url={`${decryptPath(tmpData[2].url, { isMini: true })}`}
                  height={319}>
                  <div className='bg-img'></div>
                  <ImgCustom
                    isMini={isMiniUrl}
                    screen={'post_319_auto_pc'}
                    style={{
                      height: '319px',
                      width: '100%',
                      objectFit: 'contain',
                      position: 'absolute',
                      zIndex: '10',
                      background: 'transparent',
                      cursor: 'pointer',
                    }}
                    src={tmpData[2]?.url}
                    alt=''
                  />
                </StyledTimeline.PostBlurImg>
              ) : (
                <div style={{ height: '319px' }}>
                  <StyledTimeline.PostBlurImg
                    url={`${funcThumbnailIMG(
                      decryptPath(tmpData[2].url, {
                        isMini: true,
                      })
                    )}`}
                    height={319}>
                    <div className='bg-img'></div>
                    <ImgCustom
                      isMini={isMiniUrl}
                      screen={'post_319_auto_pc'}
                      style={{
                        height: '319px',
                        width: '100%',
                        objectFit: 'contain',
                        position: 'absolute',
                        zIndex: '10',
                        background: 'transparent',
                        cursor: 'pointer',
                      }}
                      src={funcThumbnailIMG(decryptPath(tmpData[2]?.url))}
                      alt=''
                    />
                  </StyledTimeline.PostBlurImg>
                  <StyledTimeline.ButtonPlay>
                    <PlayIcon />
                  </StyledTimeline.ButtonPlay>
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '2px',
              width: '100%',
              cursor: 'pointer',
            }}>
            <div
              className='position-relative w-100'
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handleNavigate(item?.type, item?.id, itemParent);
              }}>
              {tmpData[1]?.type === 1 ? (
                <StyledTimeline.PostBlurImg
                  url={`${decryptPath(tmpData[1].url, { isMini: true })}`}
                  height={319}>
                  <div className='bg-img'></div>
                  <ImgCustom
                    isMini={isMiniUrl}
                    screen={'post_319_auto_pc'}
                    style={{
                      height: '319px',
                      width: '100%',
                      objectFit: 'contain',
                      position: 'absolute',
                      zIndex: '10',
                      background: 'transparent',
                    }}
                    src={tmpData[1]?.url}
                    alt=''
                  />
                </StyledTimeline.PostBlurImg>
              ) : (
                <div style={{ height: '319px', cursor: 'pointer' }}>
                  <StyledTimeline.PostBlurImg
                    url={`${funcThumbnailIMG(
                      decryptPath(tmpData[1].url, {
                        isMini: true,
                      })
                    )}`}
                    height={319}>
                    <div className='bg-img'></div>
                    <ImgCustom
                      isMini={isMiniUrl}
                      screen={'post_319_auto_pc'}
                      style={{
                        height: '319px',
                        width: '100%',
                        objectFit: 'contain',
                        position: 'absolute',
                        zIndex: '10',
                        background: 'transparent',
                      }}
                      src={funcThumbnailIMG(decryptPath(tmpData[1]?.url))}
                      alt=''
                    />
                  </StyledTimeline.PostBlurImg>
                  <StyledTimeline.ButtonPlay>
                    <PlayIcon />
                  </StyledTimeline.ButtonPlay>
                </div>
              )}
            </div>
            <div
              className='position-relative w-100 h-100'
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handleNavigate(item?.type, item?.id, itemParent);
              }}>
              {tmpData[3]?.type === 1 ? (
                <>
                  <StyledTimeline.PostBlurImg
                    url={`${decryptPath(tmpData[3].url, { isMini: true })}`}
                    height={319}>
                    <div className='bg-img'></div>
                    <ImgCustom
                      isMini={isMiniUrl}
                      screen={'post_319_auto_pc'}
                      style={{
                        height: '319px',
                        width: '100%',
                        objectFit: 'contain',
                        position: 'absolute',
                        zIndex: '10',
                        background: 'transparent',
                      }}
                      src={tmpData[3]?.url}
                      alt=''
                    />
                    {tmpData?.length > 4 && (
                      <>
                        <div className='div-blue-img'></div>
                        <div className='number-img'>+{Number(tmpData?.length) - 3}</div>
                      </>
                    )}
                  </StyledTimeline.PostBlurImg>
                </>
              ) : (
                <div style={{ height: '319px ', position: 'relative', width: '100%' }}>
                  <StyledTimeline.PostBlurImg
                    url={`${funcThumbnailIMG(
                      decryptPath(tmpData[3].url, {
                        isMini: true,
                      })
                    )}`}
                    height={319}>
                    <div className='bg-img'></div>
                    <ImgCustom
                      isMini={isMiniUrl}
                      screen={'post_319_auto_pc'}
                      style={{
                        height: '319px',
                        width: '100%',
                        objectFit: 'contain',
                        position: 'absolute',
                        zIndex: '10',
                        background: 'transparent',
                      }}
                      src={funcThumbnailIMG(decryptPath(tmpData[3]?.url))}
                      alt=''
                    />
                  </StyledTimeline.PostBlurImg>
                  {tmpData?.length === 4 && (
                    <StyledTimeline.ButtonPlay>
                      <PlayIcon />
                    </StyledTimeline.ButtonPlay>
                  )}
                  {tmpData?.length > 4 && (
                    <>
                      <div className='div-blue-img' style={{ top: '0' }}></div>
                      <div className='number-img' style={{ top: '0' }}>
                        +{Number(tmpData?.length) - 3}
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
