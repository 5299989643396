import {
  AvatarInfor,
  PopupSharePost,
  LayoutCreatorRight,
  ImgCustom,
  PopupConfirmFan,
  VideoDetail,
  ViewFullImage,
  PopupUserBuyPostF021,
  PopupGeneral,
  PopupShowVideo,
  PopupLoading,
} from 'components';
import {
  ChatIcon,
  HeartActiveIcon,
  HeartIcon,
  IconBookmark,
  ShareIcon,
  ThreePointIcon,
  FlagIcon,
  FlowUnFlowIcon,
  UnFlowIcon,
  EyeV2Icon,
  HiddenV2Icon,
  TrashIcon,
  DownloadIcon,
} from 'images';
import { getEnv } from 'configs/env';
import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  callApiNew,
  convertToCurrentTime,
  decryptPath,
  ENV,
  formatCoin,
  IMG_NO_WATER_MARK,
  isAdmin,
  isAgency,
  isProdEnableLiveStream,
  linkS3,
  linkS3Video,
  linkS3VideoM3U8,
  setOverflowTablet,
  STATUS_NOT_APPROVE,
  STORAGE,
} from 'utils';
import './index.scss';
import { newPathUser } from 'constants/layout/constant';
import ImgEmptyData from 'images/img-empty-data.png';
import {
  postBookMark,
  creatorDeleteArticle,
  deleteCommentDetail,
  creatorGetPostDetail,
  postFollowReplyCommentDetail,
  postFollowCommentDetail,
  deleteReplyCommentDetail,
  resetDetailPost,
  fanFollowCreator,
  updateLikeCommentDetail,
  likePost,
  hiddenPostMypage,
  resetIDHomePage,
  resetDataInfoOther,
  updatePostLikeDetail,
} from 'store/actions/usersNew';
import Avatar from 'images/Avatar.png';
import PopupViewFullTimeLine from 'components/Account/PopupViewFullTimeLine';
import PopupViewFullMess from './comment/popupCommemt';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import EditPostIcon from 'images/EditPostIcon';
import ViewPostIcon from 'images/ViewPostIcon';
import ListIconV2 from 'images/ListIconV2';
import VideoCustomDetail from '../../recording/VideoCustomDetail';
import { useRef, useMemo } from 'react';
import SuccessRegisterIcon from 'images/SuccessRegisterIcon';
import { isMobile, isSafari } from 'react-device-detect';
import { useClickOutSize } from 'hooks/v2/clickOutSide';
import Storage from 'utils/storage';
import { hideCommentPost, logUserProfile } from 'store/actions/myPage';
import { BlueImg } from '../styled';
import ListImagesNew from './elements/ListImagesNew';
import MarkDownRead from 'components/MarkDownRead';
import CommentForm from './elements/commentForm';
import { CommentPostTimeLineShowAll } from 'components/comment/commentListShowAll';
import { useContext } from 'react';
import { ContextTimeline, ProviderTimeline } from 'pages/timeline/contextTimeline';
import FieldChanged from 'components/FieldChanged/FieldChanged';
import moment from 'moment';
import axios from 'axios';
import { onValue, ref } from 'firebase/database';
import { DBRealTimeTopList } from '../../../../../firebase/config';
import PopupDownloadVideo from 'components/Popup/downloadVideo';
import { setDataItemRankingFromId } from 'store/actions/ranking';
import { resetIDSearchBuyer, updateListLikeFromPost } from 'store/actions/users';
import HideCommentIcon from 'images/HideCommentIcon';
import { clearIdAndDataHideComment } from 'store/actions/commentHide';

const CountComment = ({ idFromRanking, dispatch, id }) => {
  const { countNumberView } = useContext(ContextTimeline);
  useEffect(() => {
    if (idFromRanking) {
      dispatch(setDataItemRankingFromId(id, 'comment', countNumberView?.countNumberComment));
    }
  }, [countNumberView?.countNumberComment, idFromRanking, dispatch, id]);
  return <span className='number'>{countNumberView.countNumberComment}</span>;
};

const RenderPostItem = ({
  item,
  index,
  dataUser,
  refOutSideThree,
  isVisiblePopup,
  setIsVisiblePopup,
  setIsVisiblePopDeletePost,
  isAuth,
  setIsShowPopupConfirm,
  setSubTititeConfirmPost,
  detailPost,
  setIsShowPopupDeletePost,
  toggleValuePost,
  thumbnail,
  onClickFullView,
  pauseVideoWhenOpenPopup,
  zipFile,
  refVideoMain,
  handleLikePost,
  handleSharePost,
  checkDisabledAction,
  bookmarkChange,
  setDataViewFull,
  setSellBy,
  contentRef,
  setLoaddingDown,
  setRenderFolowCMT,
  renderFolowCMT,
  setLoaddingDownVideo,
  loaddingDown,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const formatDate = 'YYYY/MM/DD HH:mm';

  const idFromRanking = useMemo(() => {
    return location?.state?.idFromRanking;
  }, [location?.state?.idFromRanking]);

  const idPostFromListLike = useMemo(() => {
    return location?.state?.idPostFromListLike;
  }, [location?.state?.idPostFromListLike]);

  const [urlFireBase, setUrlFireBase] = useState('');
  const isCreatorPost = useMemo(() => {
    return +dataUser?.id === +item?.user_id;
  }, [dataUser?.id, item?.user_id]);

  const isAccountNotApprove = useMemo(() => {
    if (dataUser?.status) {
      return STATUS_NOT_APPROVE.includes(+dataUser?.status);
    }
    return false;
  }, [dataUser?.status]);

  useEffect(() => {
    if (urlFireBase) {
      const messageRef = ref(DBRealTimeTopList, `/${urlFireBase}`);
      onValue(
        messageRef,
        (snapshot) => {
          if (snapshot.val()) {
            handleDownload(snapshot.val());
          }
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }, [urlFireBase]);

  const handleDownload = async (item1) => {
    refVideoMain.current?.pauseVideo();
    setLoaddingDown(true);

    const timestamp = Date.now();
    const dateObj = new Date(timestamp);
    const year = dateObj.getFullYear().toString();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObj.getDate().toString().padStart(2, '0');
    const hours = dateObj.getHours().toString().padStart(2, '0');
    const minutes = dateObj.getMinutes().toString().padStart(2, '0');
    const seconds = dateObj.getSeconds().toString().padStart(2, '0');
    const result = year + month + day + hours + minutes + seconds;

    const url = `${linkS3Video}${item1.url}`;

    const chunkSize = 10000000; // 10MB mỗi phần
    // Gửi yêu cầu GET để lấy tiêu đề và kích thước tệp
    let fileSize = 0;
    try {
      const response = await axios.head(url);
      fileSize = parseInt(response.headers['content-length'], 10);
    } catch (error) {
      console.error('Error fetching content length:', error);
      setLoaddingDown(false);
      return;
    }

    if (!fileSize || isNaN(fileSize)) {
      console.error('Unable to retrieve file size.');
      setLoaddingDown(false);
      return;
    }

    if (fileSize > 209715200) {
      const numParts = Math.ceil(fileSize / chunkSize); // Tính số phần cần tải
      const fileChunks = new Array(numParts).fill(null); // Mảng chứa các phần video tải xuống

      // Hàm tải xuống một phần
      const loadPart = async (i) => {
        const start = i * chunkSize;
        const end = Math.min(start + chunkSize - 1, fileSize - 1);
        const rangeHeader = `bytes=${start}-${end}`;
        try {
          const response = await axios.get(url, {
            headers: { Range: rangeHeader },
            responseType: 'blob',
          });
          fileChunks[i] = response.data; // Lưu phần tải được
        } catch (error) {
          console.error(`Error downloading chunk ${i}:`, error);
          setLoaddingDown(false);
        }
      };

      // Chia tải xuống thành các batch (5 phần mỗi lần)
      const batchSize = 5;
      for (let batchStart = 0; batchStart < numParts; batchStart += batchSize) {
        const batchEnd = Math.min(batchStart + batchSize, numParts);
        const batchPromises = [];

        for (let i = batchStart; i < batchEnd; i++) {
          batchPromises.push(loadPart(i));
        }

        // Chờ cho tất cả các phần trong batch hoàn tất
        await Promise.all(batchPromises);
      }

      // Ghép nối các phần thành một Blob duy nhất
      const completeBlob = new Blob(fileChunks);

      const urlNew = window.URL.createObjectURL(completeBlob);
      const link = document.createElement('a');
      link.href = urlNew;
      link.setAttribute('download', `sharegram_video_${item?.id}_${result}.mp4`); // Tên file tải xuống
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setLoaddingDown(false);
    } else {
      axios({
        url,
        method: 'GET',
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          const total = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        },
      })
        .then((response) => {
          setLoaddingDown(false);
          document.querySelector('#root').style.overflow = 'auto';
          const url = window.URL.createObjectURL(response.data);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.href = url;
          a.download = `sharegram_video_${item?.id}_${result}.mp4`;
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => {
          window.location.href = `/not-found`;
          setLoaddingDown(false);
          throw Error(err);
        })
        .finally(() => {});
    }
  };

  const callAPIDownload = async (post_id) => {
    refVideoMain.current?.pauseVideo();
    setLoaddingDown(true);
    const url = `${ENV}/posts/single-sale/${post_id}/download`;
    const configParams = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
      },
    };

    const response = await callApiNew(url, configParams, null);
    if (response) {
      setUrlFireBase(response?.data?.relative_path);
    }
  };

  const handleDownloadVideo = async (item) => {
    setLoaddingDownVideo(true);
    const timestamp = Date.now();
    const dateObj = new Date(timestamp);
    const year = dateObj.getFullYear().toString();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObj.getDate().toString().padStart(2, '0');
    const hours = dateObj.getHours().toString().padStart(2, '0');
    const minutes = dateObj.getMinutes().toString().padStart(2, '0');
    const seconds = dateObj.getSeconds().toString().padStart(2, '0');
    const result = year + month + day + hours + minutes + seconds;

    let urlTmp = item?.medias?.filter((i) => i.type === 2)[0].url;
    const url = `${linkS3Video}${decryptPath(urlTmp)}#t=0.001`;

    const chunkSize = 10000000; // 10MB mỗi phần
    // Gửi yêu cầu GET để lấy tiêu đề và kích thước tệp
    let fileSize = 0;
    try {
      const response = await axios.head(url);
      fileSize = parseInt(response.headers['content-length'], 10);
    } catch (error) {
      console.error('Error fetching content length:', error);
      setLoaddingDownVideo(false);
      return;
    }

    if (!fileSize || isNaN(fileSize)) {
      console.error('Unable to retrieve file size.');
      setLoaddingDownVideo(false);
      return;
    }

    if (fileSize > 209715200) {
      const numParts = Math.ceil(fileSize / chunkSize); // Tính số phần cần tải
      const fileChunks = new Array(numParts).fill(null); // Mảng chứa các phần video tải xuống

      // Hàm tải xuống một phần
      const loadPart = async (i) => {
        const start = i * chunkSize;
        const end = Math.min(start + chunkSize - 1, fileSize - 1);
        const rangeHeader = `bytes=${start}-${end}`;
        try {
          const response = await axios.get(url, {
            headers: { Range: rangeHeader },
            responseType: 'blob',
          });
          fileChunks[i] = response.data; // Lưu phần tải được
        } catch (error) {
          console.error(`Error downloading chunk ${i}:`, error);
          setLoaddingDownVideo(false);
        }
      };

      // Chia tải xuống thành các batch (5 phần mỗi lần)
      const batchSize = 5;
      for (let batchStart = 0; batchStart < numParts; batchStart += batchSize) {
        const batchEnd = Math.min(batchStart + batchSize, numParts);
        const batchPromises = [];

        for (let i = batchStart; i < batchEnd; i++) {
          batchPromises.push(loadPart(i));
        }

        // Chờ cho tất cả các phần trong batch hoàn tất
        await Promise.all(batchPromises);
      }

      // Ghép nối các phần thành một Blob duy nhất
      const completeBlob = new Blob(fileChunks);


      const urlNew = window.URL.createObjectURL(completeBlob);
      const link = document.createElement('a');
      link.href = urlNew;
      link.setAttribute('download', `sharegram_video_${item?.id}_${result}.mp4`); // Tên file tải xuống
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setLoaddingDownVideo(false);
    } else {
      axios
        .get(url, {
          responseType: 'blob',
          onDownloadProgress: (progressEvent) => {},
        })
        .then((response) => {
          setLoaddingDownVideo(false);
          const blobUrl = isSafari
            ? window.URL.createObjectURL(new Blob([response.data]))
            : window.URL.createObjectURL(response.data);
          const a = document.createElement('a');
          a.href = blobUrl;
          a.download = `sharegram_video_${item?.id}_${result}.mp4`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(blobUrl);
        })
        .catch((err) => {
          window.location.href = `/not-found`;
          throw Error(err);
        })
        .finally(() => {
          document.querySelector('#root').style.overflow = 'auto';
        });
    }
  };

  const isCreator = useMemo(() => {
    return +item?.user_id === +dataUser?.id;
  }, [dataUser?.id, item?.user_id]);

  const checkIsShowButtonHideComment = useMemo(() => {
    return isCreator;
  }, [isCreator]);

  const handleLikePostFromListLike = (value) => {
    const data =
      value === 1
        ? {
            account_id: detailPost?.account_id,
            account_name: detailPost?.account_name,
            avatar: detailPost?.avatar,
            content: detailPost?.content,
            like_created_at: moment().format('YYYY-MM-DD'),
            post_id: idPostFromListLike,
            title: detailPost?.title,
            type: detailPost?.type,
            user_id: detailPost?.user_id,
          }
        : null;
    dispatch(updateListLikeFromPost(idPostFromListLike, data));
  };

  return (
    <>
      <div style={{ position: 'relative' }}>
        {/* {detailPost?.is_active === 0 && !isAdmin && (
          <div className='wrapper-approve-post'>
            <WaitApprovePostIcon />
            <p className='text'>
              {t('Popup.AdminManagementPost.post_is_awaiting_approval_from_admin')}
            </p>
          </div>
        )} */}
        {(detailPost?.post_status !== 'public' || detailPost?.creator_only === 1) && (
          <div className='wrap-status'>
            {detailPost?.post_status &&
              detailPost?.post_status !== 'public' &&
              !isAdmin &&
              !isAgency && (
                <div className='wrapper-approve-post'>
                  <label
                    className={`${detailPost?.post_status}-status`}
                    htmlFor={`comment-item-${detailPost?.id}`}>
                    {t(`DetailCard.${detailPost?.post_status}`)}
                  </label>
                </div>
              )}
            {detailPost?.creator_only === 1 && !isAdmin && !isAgency && (
              <div className='wrapper-approve-post'>
                <label
                  className='only-creator-status'
                  style={{ display: 'flex' }}
                  htmlFor={`comment-item-${detailPost?.id}`}>
                  {t(`DetailCard.onlyCreator`)}
                </label>
              </div>
            )}
          </div>
        )}
        <div className='d-flex justify-content-between'>
          <AvatarInfor
            images={item?.avatar}
            name={item?.account_name}
            content1={item}
            onClick={
              !isAdmin &&
              !isAgency &&
              (() => {
                dispatch(resetIDHomePage());
                dispatch(resetDataInfoOther());
                navigate(`${newPathUser}${item?.account_id}`);
              })
            }
          />
          {item?.user_id === dataUser?.id ? (
            <div className='box-popup' style={{ padding: '16px' }}>
              <span
                className='btn-three-point'
                ref={refOutSideThree}
                onClick={() => {
                  setIsVisiblePopup(isVisiblePopup === item?.id ? -1 : item?.id);
                }}>
                <ThreePointIcon style={{ color: '#666565' }} className='three-point-icon' />
              </span>

              {isVisiblePopup === item?.id && (
                <div className='popup-edit-comment popup-menu-post-sale'>
                  {item?.is_active !== 0 &&
                    item?.is_active !== 2 &&
                    item?.user_id === dataUser?.id && (
                      <div
                        className='item'
                        onMouseDown={() => {
                          if (!isAuth) {
                            setIsVisiblePopup(-1);
                            dispatch(logUserProfile(item));
                          } else {
                            dispatch(hiddenPostMypage(item.id, () => {}));
                            toggleValuePost(index, 'is_hided', item?.is_hided === 1 ? 0 : 1);
                            setIsVisiblePopup(-1);
                          }
                        }}>
                        {item?.is_hided === 0 || !item?.is_hided ? (
                          <EyeV2Icon />
                        ) : (
                          <HiddenV2Icon />
                        )}
                        <span className='text'>
                          {item?.is_hided === 0 || !item?.is_hided
                            ? t('Common.hiddenPost')
                            : t('Common.openPost')}
                        </span>
                      </div>
                    )}
                  <div
                    className='item'
                    onMouseDown={() => {
                      if (!isAuth) {
                        setIsVisiblePopup(-1);
                        dispatch(logUserProfile(item));
                      } else {
                        handleDownloadVideo(item);
                        setIsVisiblePopup(-1);
                      }
                    }}>
                    <DownloadIcon style={{ marginRight: '3px' }} />
                    <span className='text'>{t('Affiliator.AF005.download')}</span>
                  </div>
                  <div
                    className='item'
                    onMouseDown={() => {
                      if (!isAuth) {
                        dispatch(logUserProfile());
                      } else {
                        navigate(`${newPathUser}post/update/${item?.id}`, {
                          state: {
                            isEditHomePage: location.state?.isEditHomePage,
                            account_id: location?.state?.account_id,
                          },
                        });
                      }
                    }}>
                    <EditPostIcon />
                    <span className='text'>{t('Common.buttonEdit')}</span>
                  </div>
                  <div
                    className='item'
                    onMouseDown={() => {
                      if (!isAuth) {
                        dispatch(logUserProfile());
                      } else {
                        navigate(`${newPathUser}post-content-mode-2/${item?.id}`);
                      }
                    }}>
                    <ViewPostIcon />
                    <span className='text'>{t('PostDetail.pageAfterBuy')}</span>
                  </div>
                  <div
                    className='item'
                    onMouseDown={() => {
                      if (!isAuth) {
                        dispatch(logUserProfile());
                      } else {
                        dispatch(resetIDSearchBuyer());
                        window.sessionStorage.setItem('content_id', item?.id);
                        navigate(`${newPathUser}buyer`, {
                          state: { content_id: item?.id, title: item?.title },
                        });
                      }
                    }}>
                    <ListIconV2 />
                    <span className='text'>{t('Common.buyerListForEachContent')}</span>
                  </div>

                  {item?.is_my_post === 1 && (
                    <div
                      className='item'
                      onMouseDown={() => {
                        if (!isAuth) {
                          setIsVisiblePopup(-1);
                          dispatch(logUserProfile());
                        } else {
                          setIsVisiblePopDeletePost({ id: item.id });
                          setIsVisiblePopup(-1);
                        }
                      }}>
                      <TrashIcon />
                      <span className='text'>{t('Common.buttonDelete')}</span>
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div
              className={`box-popup ${(isAgency || isAdmin) && 'disabled'}`}
              style={{ padding: '16px' }}>
              <span
                className='btn-three-point'
                ref={refOutSideThree}
                onClick={() => {
                  !isAgency && setIsVisiblePopup(isVisiblePopup === item?.id ? -1 : item?.id);
                }}>
                {!isAdmin && (
                  <ThreePointIcon
                    style={{ color: '#666565', opacity: isAgency || isAdmin ? '0.5' : '1' }}
                    className='three-point-icon'
                  />
                )}
              </span>

              {isVisiblePopup === item?.id && (
                <div className='popup-edit-comment popup-menu-post-sale'>
                  <>
                    <div
                      className='item'
                      onMouseDown={() => {
                        if (!isAuth) {
                          setIsVisiblePopup(-1);
                          dispatch(logUserProfile());
                        } else {
                          if (idFromRanking) {
                            dispatch(setDataItemRankingFromId(item?.user_id, 'follow'));
                          }
                          dispatch(
                            fanFollowCreator(item?.user_id, () => {
                              setRenderFolowCMT({
                                id: item.user_id,
                                flg: !renderFolowCMT?.flg,
                              });
                            })
                          );
                          toggleValuePost(
                            index,
                            'is_followed',
                            item?.is_followed === 1 ? 0 : 1
                          );
                          setIsVisiblePopup(-1);
                        }
                      }}>
                      {item?.is_followed === 0 || !item?.is_followed ? (
                        <FlowUnFlowIcon />
                      ) : (
                        <UnFlowIcon />
                      )}
                      <span className='text'>
                        {item?.is_followed === 0 || !item?.is_followed
                          ? t('comment.listComment.flow')
                          : t('comment.listComment.unflow')}
                      </span>
                    </div>
                    <div
                      className='item'
                      onMouseDown={() => {
                        if (!isAuth) {
                          setIsVisiblePopup(-1);
                          dispatch(logUserProfile());
                        } else {
                          setIsVisiblePopup(-1);
                          navigate(`${newPathUser}post-report`, {
                            state: {
                              fan_id: item?.user_id,
                              fan_name: item?.account_name || item?.full_name,
                              post_id: item?.id,
                            },
                          });
                        }
                      }}>
                      <FlagIcon />
                      <span className='text'>{t('comment.listComment.iconFlag')}</span>
                    </div>
                  </>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <div className='content block'>
        <div className='package-row' style={{ marginTop: '0px', marginBottom: '16px' }}>
          <div className='package-row-item'>
            {thumbnail?.public_flag === 1 ? (
              <BlueImg
                className='csp'
                onClick={onClickFullView}
                url={`${decryptPath(thumbnail?.url, { isMini: true })}`}>
                <div className='bg-img'></div>
                {isAdmin && detailPost?.medias_changed?.thumbnail === 1 && <FieldChanged />}
                <ImgCustom
                  screen='post_330_auto_pc'
                  src={thumbnail?.url}
                  className='video-detail main_video cover-img'
                  style={{ marginTop: 0 }}
                  avatarErr={false}
                />
              </BlueImg>
            ) : (
              <div className='video-detail trailer_video'>
                {isAdmin && detailPost?.medias_changed?.thumbnail === 1 && <FieldChanged />}
                <img
                  src={IMG_NO_WATER_MARK}
                  alt=''
                  className='video-detail trailer_video'
                  style={{ marginTop: 0 }}
                />
              </div>
            )}
          </div>
        </div>

        {item?.user_id !== dataUser?.id &&
          item?.is_media_display === 1 &&
          !isAdmin &&
          !isAgency &&
          detailPost?.medias?.map(
            (item) =>
              item?.type === 3 && (
                <div className='show-video-sample'>
                  {item?.public_flag === 1 && thumbnail?.public_flag === 1 ? (
                    <PopupShowVideo
                      cb={pauseVideoWhenOpenPopup}
                      item={detailPost?.medias?.filter((item) => item.type === 3)}
                      thumbnail={thumbnail.url}
                    />
                  ) : (
                    <PopupShowVideo water_mark={false} />
                  )}
                </div>
              )
          )}

        {(isAdmin || isAgency) &&
          detailPost?.medias?.map(
            (item) =>
              item?.type === 3 && (
                <div className='sale-content show-video-sample'>
                  {isAdmin && detailPost?.medias_changed?.trailer === 1 && <FieldChanged />}
                  {item?.public_flag === 1 && thumbnail?.public_flag === 1 ? (
                    <PopupShowVideo
                      cb={pauseVideoWhenOpenPopup}
                      item={detailPost?.medias?.filter((item) => item.type === 3)}
                      thumbnail={thumbnail.url}
                    />
                  ) : (
                    <PopupShowVideo water_mark={false} />
                  )}
                </div>
              )
          )}
        <div style={{ marginBottom: '16px' }}>
          {isAdmin || isAgency ? (
            detailPost?.medias?.map(
              (item) =>
                item?.type === (zipFile ? 3 : 2) && (
                  <>
                    {item?.public_flag === 1 &&
                    thumbnail?.public_flag === 1 &&
                    decryptPath(item.url)?.indexOf('.m3u8') === -1 ? (
                      <VideoDetail
                        ref={refVideoMain}
                        className='video-detail main_video'
                        link={item.url}
                        background_video={decryptPath(thumbnail?.url)}
                      />
                    ) : item?.public_flag === 1 &&
                      thumbnail?.public_flag === 1 &&
                      decryptPath(item.url)?.indexOf('.m3u8') !== -1 ? (
                      <div className='content-image'>
                        <VideoCustomDetail
                          src={`${linkS3VideoM3U8}${decryptPath(item.url) + '#t=0.1'}`}
                          thumbnail={`${decryptPath(thumbnail?.url)}`}
                          isLiveArchive
                          className='video-detail'
                          isDetailPost
                          handleNavigate={() => {}}
                        />
                      </div>
                    ) : (
                      <div>
                        <img
                          src={IMG_NO_WATER_MARK}
                          alt=''
                          className='video-detail trailer_video'
                          style={{
                            marginTop: 0,
                            height: '400px',
                            width: '100%',
                            objectFit: 'cover',
                          }}
                        />
                      </div>
                    )}
                  </>
                )
            )
          ) : item?.user_id !== dataUser?.id && item?.is_media_display === 1 ? (
            <>
              {detailPost?.medias?.map(
                (item) =>
                  item?.type === (zipFile ? 3 : 2) && (
                    <>
                      {item?.public_flag === 1 &&
                      thumbnail?.public_flag === 1 &&
                      decryptPath(item.url)?.indexOf('.m3u8') === -1 ? (
                        <VideoDetail
                          ref={refVideoMain}
                          className='video-detail main_video'
                          link={item.url}
                          background_video={decryptPath(thumbnail?.url)}
                        />
                      ) : item?.public_flag === 1 &&
                        thumbnail?.public_flag === 1 &&
                        decryptPath(item.url)?.indexOf('.m3u8') !== -1 ? (
                        <div className='content-image'>
                          <VideoCustomDetail
                            src={`${linkS3VideoM3U8}${decryptPath(item.url) + '#t=0.1'}`}
                            thumbnail={`${decryptPath(thumbnail?.url)}`}
                            isLiveArchive
                            className='video-detail'
                            isDetailPost
                            handleNavigate={() => {}}
                          />
                        </div>
                      ) : (
                        <div className='video-detail main_video'>
                          <BlueImg url={`${decryptPath(thumbnail?.url, { isMini: true })}`}>
                            <div className='bg-img'></div>
                            <ImgCustom
                              src={thumbnail?.url}
                              className='video-detail main_video cover-img'
                              style={{ marginTop: 0 }}
                              avatarErr={false}
                            />
                          </BlueImg>
                        </div>
                      )}
                    </>
                  )
              )}
            </>
          ) : (
            <>
              {item?.medias?.map(
                (itemMedias) =>
                  itemMedias?.type === 3 && (
                    <>
                      {itemMedias?.public_flag === 1 && thumbnail?.public_flag === 1 ? (
                        <VideoDetail
                          className='video-detail trailer_video post-sale-video'
                          link={itemMedias.url}
                          background_video={decryptPath(thumbnail?.url)}
                        />
                      ) : (
                        <div className='video-detail trailer_video'>
                          <img
                            src={IMG_NO_WATER_MARK}
                            alt=''
                            className='video-detail trailer_video'
                            style={{ marginTop: 0 }}
                          />
                        </div>
                      )}
                    </>
                  )
              )}
            </>
          )}
          {/* {zipFile && item?.user_id !== dataUser?.id && item?.is_media_display === 1 && (
        <div
          className={`file-zip `}
          style={{ position: 'relative' }}
          onClick={() => handleDownloadFile(zipFile)}>
          <div className={`file-zip-content ${+isProdEnableLiveStream === 1 ? 'is-pointer' : ''}`}>
            <ZipFileIconV2 className='anticon file-zip-anticon' />
            <div className='file_name name-file-zip'>Download</div>
          </div>
        </div>
      )} */}
        </div>

        <div className='list-img-detail'>
          {(() => {
            const data = detailPost?.medias
              ?.filter((itemMedias) => itemMedias?.type === 1)
              .filter((itemMedias) => itemMedias?.public_flag === 1);

            const dataChanged = detailPost?.medias_changed?.images;

            return (
              data?.length > 0 && (
                <ListImagesNew
                  images={data}
                  dataChanged={dataChanged}
                  onClickImage={setDataViewFull}
                />
              )
            );
          })()}
        </div>

        {item?.content && (
          <div className='package' style={{ marginTop: '16px' }}>
            <span className='text-title'>{t('PostDetail.productDesc')}</span>
            <div className='text-content'>
              <MarkDownRead isMeta content={item?.content} />
            </div>
          </div>
        )}

        {item?.packages?.length > 0 && (
          <div className='package'>
            <span className='text-title'>{t('PostDetail.planContent')}</span>
            <div className='text-content'>
              {item?.all_package === 1 ? t('Common.allPlan') : item.package_names}
            </div>
          </div>
        )}
        {item?.number_part && (
          <div className='number-part package'>
            <span className='text-title'>{t('PostDetail.partNunber')}</span>
            <div className='text-content'>{item.number_part}</div>
          </div>
        )}
        {item?.public_time && item?.unpublic_time && (
          <div className='date package'>
            <span className='text-title'>{t('PostDetail.salesPeriod')}</span>
            <div className='text-content'>
              {item?.public_time &&
                convertToCurrentTime(item?.public_time, 'YYYY/MM/DD HH:mm:ss')}
              {` ~ `}
              {item?.unpublic_time &&
                convertToCurrentTime(item?.unpublic_time, 'YYYY/MM/DD HH:mm:ss')}
            </div>
          </div>
        )}

        {item?.price_discount && (
          <div className='package price'>
            <div className='left'>
              <span className='text-title'>{t('PostDetail.discount')}</span>
              <div className='text-price'>{item.discount}%</div>
            </div>
          </div>
        )}

        {item?.price_discount && (
          <div className='package price'>
            <div className='left'>
              <span className='text-title'>{t('PostDetail.dateStartEnd')}</span>
              <div className='text-price'>
                {convertToCurrentTime(item?.discount_start, 'YYYY/MM/DD HH:mm')} -{' '}
                {convertToCurrentTime(item?.discount_end, 'YYYY/MM/DD HH:mm')}
              </div>
            </div>
          </div>
        )}

        {detailPost?.price !== null && (
          <div className='package price'>
            <div className='left'>
              <>
                <div className={`text-title`}>{t('Popup.sellingPrice')}</div>
                <div className='d-flex w-100'>
                  <div className='text-price'>
                    {detailPost?.price_discount
                      ? formatCoin(detailPost?.price_discount)
                      : formatCoin(detailPost?.price) || 0}
                    {i18n.language === 'en' && ' '}
                    {t('Common.pt')}
                  </div>
                  {detailPost?.price_discount && (
                    <p
                      className='point-price'
                      style={{
                        textDecorationLine: 'line-through',
                        marginLeft: '8px',
                        marginBottom: '0',
                      }}>
                      {formatCoin(detailPost?.price)} {i18n.language === 'en' && ' '} Pt
                    </p>
                  )}
                </div>
              </>
            </div>
          </div>
        )}

        <div className='package'>
          <span className='text-title'>{t('PostDetail.purchasePremiumTicket')}</span>
          <div className='text-content'>
            {item?.number_premium_ticket
              ? t('PostDetail.premiumTicketTarget')
              : t('PostDetail.notPremiumTicketTarget')}
          </div>
        </div>

        {item?.number_premium_ticket && (
          <div className='package price'>
            <div className='left'>
              <span className='text-title'>{t('PostDetail.numberPremiumTicket')}</span>
              <div className='text-price'>
                {item.number_premium_ticket}
                {i18n.language === 'en' && ' '}
                {t('Common.sheet')}
              </div>
            </div>
          </div>
        )}
        {isAccountNotApprove && !isCreatorPost && item?.creator_only === 1 && (
          <div className='block-notification-request'>
            <div className='icon'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='18'
                height='18'
                viewBox='0 0 18 18'
                fill='none'>
                <path
                  d='M9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75Z'
                  stroke='#DC143C'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M11.25 6.75L6.75 11.25'
                  stroke='#DC143C'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M11.25 11.25L6.75 6.75'
                  stroke='#DC143C'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </div>
            <div className='information-request'>
              <span>{t('PostDetail.notificationRequest1')}</span>
              <span>
                <span
                  className='text-redirect'
                  onClick={() => {
                    navigate(`/profile/info`, {
                      state: {
                        redirectBlockRequest: true,
                      },
                    });
                  }}>
                  {t('PostDetail.notificationRequestTextRedirect')}
                </span>
                {t('PostDetail.notificationRequest2')}
              </span>
            </div>
          </div>
        )}
        {item?.user_id !== dataUser?.id &&
          !item?.is_media_display &&
          (isAccountNotApprove ? item?.creator_only === 0 : true) && (
            <div className='right package'>
              {isAdmin ? (
                <></>
              ) : (
                <PopupUserBuyPostF021
                  data_post={detailPost}
                  data_profile={dataUser}
                  sellBy={setSellBy}
                />
              )}
            </div>
          )}

        {!!item?.is_media_display &&
          item?.user_id !== dataUser?.id &&
          !isAdmin &&
          !isAgency && (
            <button
              className='btn bold btn-danger hover-blue mt-3'
              style={{
                background: '#28c76f',
                border: 'none',
                padding: '8px 24px',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}
              onClick={() => callAPIDownload(item?.id)}
              disabled={isAdmin || isAgency || loaddingDown}>
              <svg
                width={24}
                height={24}
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M8.0625 8.8125L12 12.749L15.9375 8.8125'
                  stroke='white'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M12 2.25L12 12.7472'
                  stroke='white'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M20.25 12.75L20.25 18C20.25 18.1989 20.171 18.3897 20.0303 18.5303C19.8897 18.671 19.6989 18.75 19.5 18.75L4.5 18.75C4.30109 18.75 4.11032 18.671 3.96967 18.5303C3.82902 18.3897 3.75 18.1989 3.75 18L3.75 12.75'
                  stroke='white'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
              {t('Affiliator.AF005.download')}
            </button>
          )}
      </div>

      <div
        className={`interactive ${!item?.is_media_display && item?.type === 1 && 'disabled'} ${
          (isAdmin || isAgency) && 'disabled'
        }`}
        style={{ background: '#FAFAFA', opacity: (isAdmin || isAgency) && 0.4 }}
        ref={contentRef}>
        <div className='left'>
          <div className={`item ${checkDisabledAction(item) && 'disabled'}`}>
            {item?.is_liked === 1 ? (
              <>
                {/* đã like */}
                <HeartActiveIcon
                  className={`like-icon active`}
                  onClick={() => {
                    if (idFromRanking) {
                      dispatch(
                        setDataItemRankingFromId(
                          item?.id,
                          'like',
                          item?.is_liked === 1 ? 0 : 1
                        )
                      );
                    }
                    if (idPostFromListLike) {
                      handleLikePostFromListLike(item?.is_liked === 1 ? 0 : 1);
                    }
                    if (!isAuth) {
                      dispatch(logUserProfile(item));
                    } else {
                      !checkDisabledAction(item) &&
                        toggleValuePost(index, 'is_liked', item?.is_liked === 1 ? 0 : 1);
                      !checkDisabledAction(item) && handleLikePost(item, item?.id);
                    }
                  }}
                  disabled={checkDisabledAction(item)}
                />
              </>
            ) : (
              <>
                {/* chưa like */}
                <HeartIcon
                  className={`like-icon`}
                  onClick={() => {
                    if (!isAuth) {
                      dispatch(logUserProfile(item));
                    } else {
                      if (idFromRanking) {
                        dispatch(
                          setDataItemRankingFromId(
                            item?.id,
                            'like',
                            item?.is_liked === 1 ? 0 : 1
                          )
                        );
                      }
                      if (idPostFromListLike) {
                        handleLikePostFromListLike(item?.is_liked === 1 ? 0 : 1);
                      }
                      !checkDisabledAction(item) &&
                        toggleValuePost(index, 'is_liked', item?.is_liked === 1 ? 0 : 1);
                      !checkDisabledAction(item) && handleLikePost(item, item?.id);
                    }
                  }}
                  disabled={checkDisabledAction(item)}
                />
              </>
            )}
            <span className='number'>{item?.number_like}</span>
          </div>
          <div className={`item ${checkDisabledAction(item) && 'disabled'}`}>
            <ChatIcon className='chat-icon' disabled={checkDisabledAction(item)} />
            {/* <span className='number'>{item?.number_comment}</span> */}
            <CountComment id={item?.id} idFromRanking={idFromRanking} dispatch={dispatch} />
          </div>
          {/* <div className={`item ${checkDisabledAction(item) && 'disabled'}`}>
        <RePostIcon />
        <span className='number'>{item?.number_repost ?? 0}</span>
      </div> */}

          <div className={`item ${checkDisabledAction(item) && 'disabled'}`}>
            <ShareIcon
              className={`share-icon`}
              onClick={() => {
                if (!isAuth) {
                  dispatch(logUserProfile(item));
                } else {
                  handleSharePost({
                    link: `${getEnv('REACT_APP_FAN_URL')}${newPathUser}post/${item?.id}`,
                    title: item.title,
                  });
                }
              }}
              disabled={checkDisabledAction(item)}
            />
          </div>
        </div>
        {checkIsShowButtonHideComment && (
          <div className={`item ${checkDisabledAction(item) && 'disabled'}`}>
            <HideCommentIcon
              disabled={checkDisabledAction(item)}
              className=''
              onClick={() => {
                dispatch(clearIdAndDataHideComment());
                navigate(`/comment-hide/${item?.id}`);
              }}
            />
          </div>
        )}
        {item?.user_id !== dataUser?.id && (
          <div className={`item ${checkDisabledAction(item) && 'disabled'}`}>
            <IconBookmark
              style={{
                color: item?.is_bookmarked === 1 ? '#dc143c' : 'inherit',
              }}
              fill={item?.is_bookmarked === 1 ? '#dc143c' : 'none'}
              onClick={() => {
                if (!isAuth) {
                  dispatch(logUserProfile());
                } else {
                  !checkDisabledAction(item) &&
                    toggleValuePost(index, 'is_bookmarked', item?.is_bookmarked === 1 ? 0 : 1);
                  !checkDisabledAction(item) && bookmarkChange(item);
                }
              }}
              className='cls-icon'
            />
          </div>
        )}
      </div>
    </>
  );
};

const PostContentSaleDetail = ({ setListTimeLine, listTimeLine }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [tab, setTab] = useState(1);
  const [popupCopyLink, setPopupCopyLink] = useState(null);
  const [trickReloadComment, setTrickReloadComment] = useState(null);
  const { check_buy_post } = useSelector((state) => state.sellPost);
  const {
    listPostTimeLine,
    detailPost,
    loading,
    isLoadingFollowCreate,
    isCheckResetDetailPost,
  } = useSelector((state) => state.usersNew);
  const [isShowPopup, setShowPopup] = useState({
    status: false,
    dataItem: {},
  });
  const [isShowPopupMess, setShowPopupMess] = useState({
    status: false,
    dataItemMess: {},
  });
  const [valueComments, setValueComments] = useState(() =>
    Array.from({ length: 1 }, () => '')
  );
  const [checkSendChat, setCheckSendChat] = useState(null);
  const { data: userProfile, dataUser } = useSelector((state) => state.users);
  const [isVisiblePopup, setIsVisiblePopup] = useState(-1);
  const [isVisiblePopUpDeletePost, setIsVisiblePopDeletePost] = useState(null);
  const [isVisiblePopUpDeletePostComplete, setIsVisiblePopDeletePostComplete] =
    useState(false);
  const [popupDeleteComment, setPopupDeleteComment] = useState(null);
  const [popupHideComment, setPopupHideComment] = useState(null);
  const [thumbnail, setThumbnail] = useState(IMG_NO_WATER_MARK);
  const [dataViewFull, setDataViewFull] = useState({});
  const [sellBy, setSellBy] = useState();
  const [zipFile, setZipFile] = useState(null);
  const [previousPath, setPreviousPath] = useState(null);
  const isAuth = Storage.has(STORAGE.USER_ACCESS_TOKEN);
  const [isShowPopupConfirm, setIsShowPopupConfirm] = useState(false);
  const [isShowPopupDeletePost, setIsShowPopupDeletePost] = useState(false);
  const [subTititeConfirmPost, setSubTititeConfirmPost] = useState(false);
  const [isShowPopupDeleteSuccess, setIsShowPopupDeleteSuccess] = useState(false);
  const [loaddingDown, setLoaddingDown] = useState(false);
  const [renderFolowCMT, setRenderFolowCMT] = useState({ id: null, flg: false });
  const [loaddingDownVideo, setLoaddingDownVideo] = useState(false);
  const contentRef = useRef(null);
  const refVideoMain = useRef(null);

  const { pathname, search } = useLocation();

  useEffect(() => {
    if (dataViewFull?.image?.url) {
      document.getElementById('root').style.overflow = 'hidden';
      document.getElementById('root').style.position = 'fixed';
      document.getElementById('root').style.width = '100%';
    } else {
      // document.getElementById('root').style.overflow = 'auto';
      setOverflowTablet();
      document.getElementById('root').style.position = 'revert';
    }
  }, [dataViewFull?.image?.url]);

  useEffect(() => {
    const previousPath = location?.state?.previousPath;
    if (previousPath) {
      setPreviousPath(previousPath);
    }
  }, [location.state]);

  const { id } = useParams();

  const isHidePost = useMemo(() => {
    return !isAdmin && detailPost?.is_hided === 1 && detailPost?.is_my_post === 0;
  }, [detailPost]);

  const isHidePostByAdmin = useMemo(() => {
    return !isAdmin && detailPost?.is_active === 0 && detailPost?.is_my_post === 0;
  }, [detailPost]);

  // useEffect(() => {
  //   if (isHidePost) {
  //     navigate('/not-found');
  //   }
  //   if (isHidePostByAdmin) {
  //     navigate('/not-found');
  //   }
  // }, [isHidePost, navigate]);

  useEffect(() => {
    if (detailPost?.type && !loading && !isCheckResetDetailPost) {
      if (detailPost?.type !== 2) {
        navigate('/not-found');
      }
    }
  }, [detailPost?.type, navigate, loading, isCheckResetDetailPost, detailPost]);

  useEffect(() => {
    return () => {
      dispatch(resetDetailPost());
    };
  }, []);

  useEffect(() => {
    detailPost &&
      detailPost?.medias?.map((item) => {
        if (item?.type === 4 && item?.public_flag === 1) {
          setThumbnail(item);
        }
        if (item?.type === 5) {
          setZipFile(item);
        }
      });
  }, [detailPost]);

  // useEffect(() => {
  //   if (detailPost) {
  //     setListTimeLine([detailPost]);
  //   } else {
  //     setListTimeLine([]);
  //   }
  // }, [detailPost]);

  // const isAgency = localStorage

  useEffect(() => {
    const top = document.getElementById('app_cocofans_0');
    top && top.scrollIntoView({ top: 0, left: 0, block: 'start' });
  }, []);

  const token = search?.split('&');
  const tokenAgency = localStorage.getItem(STORAGE.AGENCY_TOKEN)?.replace(/"/g, '') || false;

  const adminToken = isAgency
    ? tokenAgency
    : token[1]?.split('=')[1] ?? token[0]?.split('=')[1];

  // useEffect(() => {
  //   const agencyToken = Storage.get(STORAGE.AGENCY_TOKEN);
  //   const adminTokenAccess = Storage.get(STORAGE.USER_ACCESS_TOKEN);
  //   if (!search?.includes('adminToken') && !search?.includes('agencyToken')) {
  //     dispatch(
  //       creatorGetPostDetail(
  //         id,
  //         2,
  //         (data) => {
  //           setListTimeLine([data]);
  //         },
  //         isAgency ? agencyToken : adminTokenAccess
  //       )
  //     );
  //   }
  // }, [id]);

  useEffect(() => {
    if (detailPost) {
      setListTimeLine([detailPost]);
    } else {
      setListTimeLine && setListTimeLine([]);
    }
  }, [detailPost]);

  useEffect(() => {
    if (listPostTimeLine.length > 0) {
      setValueComments(() => Array.from({ length: listPostTimeLine?.length }, () => ''));
    }
  }, [listPostTimeLine]);

  const bookmarkChange = (post) => {
    dispatch(postBookMark(post));
  };

  const handleSharePost = (data) => {
    setPopupCopyLink(data);
  };

  const handleShowPopup = (e) => {
    setPopupCopyLink(e);
  };

  const checkDisabledAction = (item) => {
    if (item?.is_active === 0) return true;
    if (item?.post_status === 'reject' || item?.post_status === 'reviewing') return true;
    if (
      (item?.is_followed === 0 && item?.follow_flag === 1 && item?.is_my_post === 0) ||
      (!item?.is_media_display && item?.type === 1 && item?.is_my_post === 0) ||
      item?.is_active === 0 ||
      item?.is_active === 2 ||
      isAdmin
    ) {
      return true;
    }
    return false;
  };

  const handleLikePost = (post, id) => {
    dispatch(updatePostLikeDetail(post, true));
    dispatch(likePost(id));
  };

  const toggleValuePost = useCallback(
    (index, name = '', value) => {
      const listClone = JSON.parse(JSON.stringify(listTimeLine)) || [];
      if (listClone?.length > 0) {
        listClone[index][name] = value || !listClone[index][name];
        if (name === 'is_liked') {
          if (value === 1) {
            listClone[index]['number_like'] = listClone[index]['number_like'] + 1;
          } else {
            listClone[index]['number_like'] =
              listClone[index]['number_like'] > 0 ? listClone[index]['number_like'] - 1 : 0;
          }
        }
      }
      setListTimeLine(listClone);
    },
    [listTimeLine]
  );

  const deletePostTimeLine = () => {
    setIsVisiblePopDeletePost(null);
    const { id } = isVisiblePopUpDeletePost;
    dispatch(
      creatorDeleteArticle(id, () => {
        setIsVisiblePopDeletePostComplete(true);
        dispatch(resetIDHomePage());
        dispatch(resetDataInfoOther());
        navigate(`${newPathUser}`);
      })
    );
  };

  const handleDownloadFile = (item) => {
    if (+isProdEnableLiveStream === 1) {
      const a = document.createElement('a');
      const linkDownLoad = `${linkS3}${decryptPath(item.url)}`;
      a.href = linkDownLoad;
      a.download = linkDownLoad;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  //API LIKE COMMENT
  // const handleLikeComment = (data) => {
  //   const { commentId, type, dataPost } = data;
  //   type === 'comment'
  //     ? dispatch(updateLikeCommentDetail(commentId, dataPost))
  //     : dispatch(updateLikeReplyComment(commentId, dataPost));
  // };
  const handleLikeComment = (data, cb = () => {}) => {
    const { commentId, type, dataPost } = data;
    cb();
    dispatch(updateLikeCommentDetail(commentId, dataPost));
    // type === 'comment'
    //   ? dispatch(updateLikeCommentDetail(commentId, dataPost))
    //   : dispatch(updateLikeReplyComment(commentId, dataPost));
  };

  // DELETE COMMENT
  const handleDeleteComment = () => {
    const { id, type, idParent, cb = () => {}, postId } = popupDeleteComment;
    type === 'comment'
      ? dispatch(deleteCommentDetail(id, postId || null, cb))
      : dispatch(deleteReplyCommentDetail(id, postId || null, cb));
    setPopupDeleteComment(false);
  };
  // HIDE COMMENT
  const handleHideComment = () => {
    const { id, cb = () => {} } = popupHideComment;
    dispatch(hideCommentPost(id, cb));
    setPopupHideComment(false);
  };

  // FOLLOW USER IN COMMENT
  // const handleFollowUserComment = (data) => {
  //   const { id, type } = data;
  //   type === 'comment'
  //     ? dispatch(postFollowCommentDetail(id))
  //     : dispatch(postFollowReplyCommentDetail(id));
  // };
  const handleFollowUserComment = (data, onRequest = () => {}, onFinally = () => {}) => {
    const { id, type } = data;
    type === 'comment'
      ? dispatch(postFollowCommentDetail(id, onRequest, onFinally))
      : dispatch(postFollowReplyCommentDetail(id, onRequest, onFinally));
  };

  // SETHEIGHT TEXTAREA
  const handleTextarea = (index, e, id, height) => {
    if (e.target.scrollHeight > e.target.clientHeight) {
      e.target.style.height = e.target.scrollHeight + 'px';
    }
    var code = e.keyCode || e.charCode;
    if (code === 13) {
      e.target.style.height = 'inherit';
      e.target.style.height = `${e.target.scrollHeight}px`;
    }
    const { value } = e.target;
    const newCommentValues = valueComments?.map((comment, idx) => {
      if (idx !== index) {
        return comment;
      }
      return value;
    });
    setValueComments(newCommentValues);
    setCheckSendChat(id);
    if (e.target.value?.toString().trim() === '') {
      e.target.style.height = height;
      e.target.style.padding = '9px  30px  9px 12px';
      setCheckSendChat(null);
    }
  };

  const [refOutSideThree] = useClickOutSize(() => {
    setIsVisiblePopup(-1);
  });

  const pauseVideoWhenOpenPopup = () => {
    refVideoMain.current?.pauseVideo();
  };

  const onClickFullView = () => {
    const list = {
      image: thumbnail,
      listImage: [thumbnail],
      initPageActive: 0,
    };
    setDataViewFull(list);
  };

  const handleToViewHead = () => {
    contentRef.current.scrollIntoView();
  };

  const canGoBack = useMemo(() => {
    return window.history.state && window.history.state.idx > 0;
  }, []);

  return (
    <>
      {loaddingDown && <PopupDownloadVideo loadVideo={loaddingDown} />}
      {dataViewFull?.image?.url && (
        <ViewFullImage dataViewFull={dataViewFull} close={() => setDataViewFull({})} />
      )}

      {popupCopyLink && (
        <PopupSharePost isVisiblePopup={handleShowPopup} data={popupCopyLink || {}} />
      )}
      {isShowPopupMess?.status && (
        <PopupViewFullMess
          item={isShowPopupMess?.dataItemMess}
          close={() => {
            setShowPopupMess({
              ...isShowPopupMess,
              status: false,
              dataItem: {},
            });
            dispatch(creatorGetPostDetail(id, false));
          }}
        />
      )}
      {isVisiblePopUpDeletePost && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsVisiblePopDeletePost(e)}
          colorSvg='#FF9F43'
          iconNote={true}
          className='popup-delete-post-details'
          text={t('Popup.confirmDeletePost')}>
          <div className='ok btn' onClick={deletePostTimeLine}>
            {t('Common.buttonYes')}
          </div>
          <div className='btn cancel' onClick={() => setIsVisiblePopDeletePost(null)}>
            {t('Common.buttonNo')}
          </div>
        </PopupConfirmFan>
      )}
      {isVisiblePopUpDeletePostComplete && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsVisiblePopDeletePostComplete(e)}
          iconNote={true}
          text={t('Popup.deletePostSucess')}>
          <div className='ok btn' onClick={() => setIsVisiblePopDeletePostComplete(false)}>
            {t('Common.buttonYes')}
          </div>
        </PopupConfirmFan>
      )}
      {popupDeleteComment && (
        <PopupConfirmFan
          isVisiblePopup={() => setPopupDeleteComment(false)}
          colorSvg='#FF9F43'
          iconNote
          text={t('Popup.confirmDeleteComment')}
          className='popup-delete'>
          <div className='ok btn' onClick={handleDeleteComment}>
            {t('Common.buttonDelete')}
          </div>
          <div className='btn cancel' onClick={() => setPopupDeleteComment(false)}>
            {t('Common.buttonCancel')}
          </div>
        </PopupConfirmFan>
      )}
      {popupHideComment && (
        <PopupConfirmFan
          isVisiblePopup={() => setPopupHideComment(false)}
          colorSvg='#FF9F43'
          iconNote
          text={t('Popup.confirmHideComment')}
          className='popup-delete'>
          <div className='ok btn' onClick={handleHideComment}>
            {t('Common.buttonYes')}
          </div>
          <div className='btn cancel' onClick={() => setPopupHideComment(false)}>
            {t('Common.buttonNo')}
          </div>
        </PopupConfirmFan>
      )}
      {isShowPopup?.status && (
        <PopupViewFullTimeLine
          item={isShowPopup?.dataItem}
          idx={isShowPopup?.idx}
          close={() => {
            setShowPopup({
              ...isShowPopup,
              status: false,
              dataItem: {},
            });
            dispatch(creatorGetPostDetail(id, false));
          }}
        />
      )}
      {check_buy_post === 'BUY_SUCCESS' && (
        <PopupGeneral className='popup-success-buy-post-sale-premium'>
          <SuccessRegisterIcon />

          <div className='text-center success-message py-3'>
            {+sellBy === 1 || sellBy === '1'
              ? t('Popup.buyPostSuccess1', { code: detailPost?.code })
              : t('Popup.buyPostTicketSuccess', { code: detailPost?.code })}
          </div>
          <div className='button-wrapper'>
            <div className='btn ok button-navigate' onClick={() => navigate(0)}>
              {t('PostDetail.buttonReload')}
            </div>
          </div>
        </PopupGeneral>
      )}
      {(isLoadingFollowCreate || loaddingDownVideo) && (
        <PopupLoading className={'popup-loading'} />
      )}
      <>
        {listTimeLine?.length > 0 ? (
          <>
            {listTimeLine?.length > 0 &&
              listTimeLine.map((item, index) => (
                <div
                  style={{
                    borderBottom: 'none',
                    background: '#FAFAFA',
                    marginBottom: '20px',
                    borderRadius: '8px',
                  }}
                  key={item?.id}
                  className={`post-content-sale-view my-page-item ${
                    !item?.unlock_media && item?.type === 1 && 'popup_lock_post'
                  }`}>
                  {/* {renderPostItem(item, index)} */}
                  <RenderPostItem
                    item={item}
                    index={index}
                    dataUser={dataUser}
                    refOutSideThree={refOutSideThree}
                    isVisiblePopup={isVisiblePopup}
                    setIsVisiblePopup={setIsVisiblePopup}
                    isAuth={isAuth}
                    setIsShowPopupConfirm={setIsShowPopupConfirm}
                    setSubTititeConfirmPost={setSubTititeConfirmPost}
                    detailPost={detailPost}
                    setIsShowPopupDeletePost={setIsShowPopupDeletePost}
                    toggleValuePost={toggleValuePost}
                    thumbnail={thumbnail}
                    onClickFullView={onClickFullView}
                    pauseVideoWhenOpenPopup={pauseVideoWhenOpenPopup}
                    zipFile={zipFile}
                    refVideoMain={refVideoMain}
                    handleLikePost={handleLikePost}
                    handleSharePost={handleSharePost}
                    checkDisabledAction={checkDisabledAction}
                    bookmarkChange={bookmarkChange}
                    setDataViewFull={setDataViewFull}
                    setSellBy={setSellBy}
                    contentRef={contentRef}
                    setIsVisiblePopDeletePost={setIsVisiblePopDeletePost}
                    setLoaddingDown={setLoaddingDown}
                    setRenderFolowCMT={setRenderFolowCMT}
                    setLoaddingDownVideo={setLoaddingDownVideo}
                    renderFolowCMT={renderFolowCMT}
                    loaddingDown={loaddingDown}
                  />
                  <>
                    <div className='comment-wrap' style={{ padding: '0 16px' }}>
                      <CommentPostTimeLineShowAll
                        defaultCount={item?.number_comment}
                        dataPost={item}
                        handleDeleteComment={(e) => {
                          setPopupDeleteComment(e);
                        }}
                        handleHideComment={(e) => {
                          setPopupHideComment(e);
                        }}
                        handleLikeComment={handleLikeComment}
                        handleFollowUserComment={handleFollowUserComment}
                        setTrickReloadComment={setTrickReloadComment}
                        trickReloadComment={trickReloadComment}
                        id={item?.id}
                        dataProfile={dataUser}
                        idScrollable=''
                        isPopupSaleContent
                        renderFolowCMT
                        typeFollow='detailPostSale'
                      />
                    </div>
                    <div
                      className={`comment block ${(isAdmin || isAgency) && 'disabled'}`}
                      style={{ marginBottom: '16px', padding: '0 16px' }}>
                      <ImgCustom
                        screen='avatar_44_44'
                        isPlaceholderAvatar
                        src={
                          isAdmin || isAgency
                            ? Avatar
                            : dataUser?.avatar
                            ? `${dataUser?.avatar}`
                            : Avatar
                        }
                      />
                      <CommentForm
                        item={item}
                        handleToViewHead={handleToViewHead}
                        setTrickReloadComment={setTrickReloadComment}
                      />
                    </div>
                  </>
                </div>
              ))}
          </>
        ) : (
          !loading &&
          !detailPost &&
          listTimeLine?.length > 0 && (
            <div className='empty-box'>
              <ImgCustom
                src={ImgEmptyData}
                alt='empty-data'
                style={{ backgroundColor: 'transparent', paddingBottom: '10px' }}
              />
              <div>{tab === 1 ? t('MyPage.ListPostNull4') : t('MyPage.ListPostNull1')}</div>
              <div>{tab === 1 ? t('MyPage.ListPostNull5') : t('MyPage.ListPostNull2')}</div>
              <div>{tab === 1 && t('MyPage.ListPostNull6')}</div>
            </div>
          )
        )}
      </>
    </>
  );
};

export default PostContentSaleDetail;
