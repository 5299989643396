/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { STORAGE } from 'utils/constant';
import Storage from 'utils/storage';
import { checkAdminToken } from '../store/actions/admin';
import useUserRole from './useUserRole';
import { BroadcastChannel } from 'broadcast-channel';

const loginChannel = new BroadcastChannel('loginByAdminOrAgency');

const navigateByParam = () => {
  // loginChannel.postMessage('loginByAdminOrAgency'); // No reload page
  const isParams = Storage.has(STORAGE.PARAMS);
  if (isParams) {
    window.location.href = Storage.get(STORAGE.PARAMS);
  }
};

export const loginAllTabs = () => {
  loginChannel.onmessage = () => {
    navigateByParam();
    loginChannel.close();
  };
};

function useCheckLoginAdminOrAgency() {
  const { pathname, search } = useLocation();
  const isAuth = Storage.has(STORAGE.USER_ACCESS_TOKEN);
  const { isIdol } = useUserRole();
  const dispatch = useDispatch();

  const setToken = (adminToken) => {
    return new Promise((resolve, reject) => {
      Storage.set(STORAGE.USER_ACCESS_TOKEN, adminToken);

      Storage.remove(STORAGE.IS_AGENCY);
      resolve()
    })
  }

  useEffect(() => {
    if (search?.includes('adminToken')) {
      Storage.set(STORAGE.IS_ADMIN, true);
      window.sessionStorage.setItem('check_sso', true);
      const token = search?.split('&'); 
      const adminToken = token[0]?.split('=')[1];
      const csAdminId = token[1]?.length > 0 ? token[1]?.split('=')[1] : 0;

      setToken(adminToken).then(() => {
        dispatch(
          checkAdminToken(
            adminToken,
            () => {
              Storage.set(STORAGE.IS_ADMIN, true);
              Storage.set(STORAGE.USER_ACCESS_TOKEN, adminToken);
              Storage.set(STORAGE.PARAMS, pathname);
              Storage.set(STORAGE.CS_ADMIN_ID, Number(csAdminId));
              window.sessionStorage.removeItem('check_sso');
              navigateByParam();
            },
            () => {
              //Storage.remove(STORAGE.IS_ADMIN);
              Storage.remove(STORAGE.PARAMS);
              //Storage.remove(STORAGE.USER_ACCESS_TOKEN);
              //Storage.remove(STORAGE.CURRENT_USER_KEY);
              window.sessionStorage.removeItem('check_sso');
            }
          )
        );
      })
    
    } else if (isIdol && search?.includes('agencyToken')) {
      window.sessionStorage.setItem('check_sso', true);
      const token = search?.split('&');
      const accessToken = token[0]?.split('=')[1];
      const agencyToken = token[1]?.length > 0 ? token[1]?.split('=')[1] : null;
      Storage.remove(STORAGE.IS_ADMIN);

      dispatch(
        checkAdminToken(
          accessToken,
          () => {
            Storage.set(STORAGE.IS_AGENCY, true);
            Storage.set(STORAGE.USER_ACCESS_TOKEN, accessToken);
            Storage.set(STORAGE.PARAMS, pathname);
            Storage.set(STORAGE.AGENCY_TOKEN, agencyToken);
            window.sessionStorage.removeItem('check_sso');
            navigateByParam();
          },
          () => {
            Storage.remove(STORAGE.IS_AGENCY);
            Storage.remove(STORAGE.PARAMS);
            Storage.remove(STORAGE.USER_ACCESS_TOKEN);
            Storage.remove(STORAGE.CURRENT_USER_KEY);
            window.sessionStorage.removeItem('check_sso');
          }
        )
      );
    }
    // check admin login fan
    else if (search?.includes('adminFanToken')) {
      window.sessionStorage.setItem('check_sso', true);
      const token = new URLSearchParams(search).get('adminFanToken').toString();
      const userID = new URLSearchParams(search).get('userID').toString();
      dispatch(
        checkAdminToken(
          token,
          () => {
            Storage.set(STORAGE.IS_ADMIN, true);
            Storage.set(STORAGE.USER_ACCESS_TOKEN, token);
            Storage.set(STORAGE.PARAMS, pathname);
            Storage.set(STORAGE.USER_ID, userID);
            window.sessionStorage.removeItem('check_sso');
            navigateByParam();
          },
          () => {
            Storage.remove(STORAGE.IS_ADMIN);
            Storage.remove(STORAGE.PARAMS);
            Storage.remove(STORAGE.USER_ACCESS_TOKEN);
            Storage.remove(STORAGE.USER_ID, userID);
            window.sessionStorage.removeItem('check_sso');
          }
        )
      );
    }
  }, [search, pathname, isIdol, isAuth]);
}

export default useCheckLoginAdminOrAgency;
