import BackgroundImageIcon from 'images/BackgroungImageIcon';
import { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { decryptPath, linkS3 } from 'utils/utils';
import { ImgCustom } from '..';
import { ListIMG } from './styles';
import './index.scss';
import { isMobile } from 'react-device-detect';

const HomeQuickViewImages = ({ initialImages, setShowPopupFull, showPopupFull }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: initialImages?.length > 1 ? 1 : initialImages?.length,
    slidesToScroll: 1,
    swipeToSlide: true,
    prevArrow: false,
    nextArrow: false,
    arrows: false,
  };
  const widthScreen = window.innerWidth;
  const [isMobileScreen, setIsMobileScreen] = useState(false);

  useEffect(() => {
    if (widthScreen <= 991) {
      setIsMobileScreen(true);
    }
  }, [widthScreen]);

  const renderSlidesMB = () =>
    initialImages?.slice(0, 3)?.map((item, index) => (
      <div
        key={index}
        className={`background_img overflow-hidden position-relative ${
          initialImages?.length === 0 ? 'd-flex justify-content-center align-items-center' : ''
        }  `}>
        <div className={`home-quick-views-container d-flex`}>
          <ListIMG.BlurImg url={`${decryptPath(item.url, { isMini: true })}`} height={264}>
          </ListIMG.BlurImg>
          <ImgCustom
              onClick={() =>
                item.url !== '' &&
                setShowPopupFull({
                  ...showPopupFull,
                  status: true,
                  dataItem: initialImages,
                  idx: index,
                  iSMany: 2,
                })
              }
              screen='timeline_auto_375_pc'
              src={`${decryptPath(item.url)}`}
              alt='home-quick-views'
              className='cover-img'
            />
        </div>
        {initialImages?.length === 0 && <BackgroundImageIcon className='bg-icon-smaller' />}
      </div>
    ));

  const imagesList = () => {
    return (
      <div className='list-image-home-page'>
        {initialImages?.length > 0 &&
          initialImages?.map((item, index) => (
            <div className='item' key={index}>
              <ListIMG.BlueImg url={`${decryptPath(item.url, { isMini: true })}`} height={241} width={212}>
                <div className='bg-img br-8'></div>
                <ImgCustom
                  onClick={() =>
                    item.url !== '' &&
                    setShowPopupFull({
                      ...showPopupFull,
                      status: true,
                      dataItem: initialImages,
                      idx: index,
                      iSMany: 2,
                    })
                  }
                  screen='post_212_auto_pc'
                  src={`${decryptPath(item.url)}`}
                  alt='home-quick-views'
                  className='item-images'
                  style={{
                    position: 'absolute',
                    zIndex: '14',
                    background: 'transparent',
                    cursor: 'pointer',
                  }}
                />
              </ListIMG.BlueImg>
            </div>
          ))}
      </div>
    );
  };

  return (
    <>
        <>{imagesList()}</>
    </>
  );
};

export default HomeQuickViewImages;
