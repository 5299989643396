import formatMoney from 'accounting-js/lib/formatMoney.js';
import img_no_water_mark from 'images/img_no_water_mark.jpg';
import img_no_water_mark_mobile from 'images/img_no_water_mark_mobile.jpg';
import img_no_water_mark_en from 'images/img_no_water_mark_en.jpg';
import img_no_water_mark_mobile_en from 'images/img_no_water_mark_mobile_en.jpg';
import img_no_water_mark_mobile_3_column from 'images/img_no_water_mark_3_column.jpg';
import img_no_water_mark_mobile_3_column_en from 'images/img_no_water_mark_3_column_en.jpg';
import img_no_water_mark_mobile_2_column_short_video_en from 'images/img_no_water_mark_mobile_2_column_short_video_en.jpg';
import img_no_water_mark_mobile_2_column_short_video from 'images/img_no_water_mark_mobile_2_column_short_video.jpg';
import img_no_water_mark_mobile_2_column_post_content_en from 'images/img_no_water_mark_mobile_2_column_post_content_en.jpg';
import img_no_water_mark_mobile_2_column_post_content from 'images/img_no_water_mark_mobile_2_column_post_content.jpg';
import moment from 'moment';
import 'moment/locale/ja';
import { useEffect, useState } from 'react';
import i18n from 'translate/i18n';
import Storage from 'utils/storage';
import { /* Select , */ components } from 'react-select';
import { ArrowLeftIcon, CheckedIcon } from 'images';
// import FFMPEG from 'react-ffmpeg';
import { pushNotify } from '../components/Notification';
import { getEnv } from '../configs/env';
import CryptoJS from 'crypto-js';
import atob from 'atob';
import {
  AVATAR_DEFAULT,
  IMG_DEMO,
  LOGO_COCO_FANS,
  NO_IMAGE_LIVESTREAM,
  ROLE_ACCOUNT_BANNED,
  STORAGE,
  widthSreen,
} from './constant';
import { newPathUser } from 'constants/layout/constant';
import { isIOS, isTablet } from 'react-device-detect';
import { jwtVerify } from 'jose';

export const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

export const capitalizeFirstLetter = (text) => text.charAt(0).toUpperCase() + text.slice(1);

export const randomize = (data) => Math.floor(Math.random() * data.length - 1);

export const truncate = (text, n) => (text?.length > n ? text.substr(0, n - 1) + '...' : text);

export const isAuth = Storage.has(STORAGE.USER_ACCESS_TOKEN);

export const detectDomain = () => {
  const parts = window.location.hostname.split('.');
  let last_index = -2;
  const last = parts[parts.length - 1];
  const is_localhost = last === 'localhost';
  if (is_localhost) {
    last_index = -1;
  }
  let subdomain = parts.slice(0, last_index).join('.');
  if (!subdomain) {
    subdomain = null;
  }
  return { subdomain };
};

/**
 *
 * @param {*} date
 */
export function formatDateToString(date) {
  return `${date.getFullYear()}-${`${date.getMonth() + 1}`.padStart(
    2,
    0
  )}-${`${date.getDate()}`.padStart(2, 0)}`;
}

export function formatToDateTimeString(date) {
  if (!date) return '';
  return `${date.getFullYear()}/${`${date.getMonth() + 1}`.padStart(
    2,
    0
  )}/${`${date.getDate()}`.padStart(2, 0)} ${`${date.getHours()}`.padStart(
    2,
    0
  )}:${`${date.getMinutes()}`.padStart(2, 0)}`;
}

// Check isArray
export function isMappable(array) {
  if (Array.isArray(array)) return array?.length > 0;
  return false;
}

export function formatCoin(value = 0) {
  if (value === 0) {
    return value;
  } else {
    if (value % 1 !== 0) {
      const result = formatMoney(value, {
        symbol: '',
        precision: 2,
        thousand: ',',
        decimal: '.',
      });
      return result;
    }
    const result = formatMoney(value, {
      symbol: '',
      precision: 0,
      thousand: ',',
      decimal: '.',
    });
    return result;
  }
}

// export function convertTimeJp(time) {
//   var createDate = new Date(time);
//   const timeSplit = createDate.toLocaleString('ja-JP', { timeZone: 'UTC' });

//   var date = timeSplit.split(' ')[0];
//   var yyyy = `${date.split('/')[0]}/`;
//   var MM = date.split('/')[1];
//   var dd = date.split('/')[2];
//   if (+MM < 10) MM = `0${MM}/`;
//   else MM = `${MM}/`;
//   if (+dd < 10) dd = `0${dd}/`;
//   else dd = `${dd}/`;
//   const dateConvert = [yyyy, MM, dd].join('');

//   const hhmmss = timeSplit.split(' ')[1];
//   const arrSpitTime = hhmmss.split(':').slice(0, 2);
//   var hour = +arrSpitTime[0];
//   if (+hour < 10) hour = `0${hour}`;

//   var minute = +arrSpitTime[1];
//   if (+minute < 10) minute = '0' + minute;

//   return `${dateConvert} ${hour}:${minute}`;
// }

// export function convertDateJp(time) {
//   var createDate = new Date(time);
//   const timeSplit = createDate.toLocaleString('ja-JP', { timeZone: 'UTC' });

//   var date = timeSplit.split(' ')[0];
//   var yyyy = `${date.split('/')[0]}年`;
//   var MM = date.split('/')[1];
//   var dd = date.split('/')[2];
//   if (+MM < 10) MM = `0${MM}月`;
//   else MM = `${MM}月`;
//   if (+dd < 10) dd = `0${dd}日`;
//   else dd = `${dd}日`;
//   const dateConvert = [yyyy, MM, dd].join('');

//   return `${dateConvert}`;
// }

// export function convertTimeAgo(time) {
//   function timeSince(date) {
//     var seconds = Math.floor((new Date() - date) / 1000);

//     var interval = seconds / 31536000;

//     if (interval > 1) {
//       return Math.floor(interval) + ' 年前';
//     }
//     interval = seconds / 2592000;
//     if (interval > 1) {
//       return Math.floor(interval) + ' 月前';
//     }
//     interval = seconds / 86400;
//     if (interval > 1) {
//       return Math.floor(interval) + ' 日々前';
//     }
//     interval = seconds / 3600;
//     if (interval > 1) {
//       return Math.floor(interval) + ' 時間前';
//     }
//     interval = seconds / 60;
//     if (interval > 1) {
//       return Math.floor(interval) + ' 分前';
//     }
//     return Math.floor(seconds) + ' 秒前';
//   }
//   const dateOld = new Date(time);
//   const now = new Date();
//   const timestamp = now - dateOld;
//   const date = new Date(Date.now() - timestamp);
//   return timeSince(date);
// }

export const dateFormatJP = (date) => {
  return moment(date).locale('ja').format('LL');
};

export const secretKeyCard = getEnv('REACT_APP_PAYMENT_SECRET');
export const isProdEnable = getEnv('REACT_APP_PROD_ENABLE');
export const isProdEnableLiveStream = getEnv('REACT_APP_PROD_LIVESTREAM_ENABLE');
export const isProdTrialMode = getEnv('REACT_APP_PROD_TRIAL_MODE');
export const linkS3 = getEnv('REACT_APP_IMAGE_URL');
export const linkS3Video = getEnv('REACT_APP_VIDEO_URL');
export const linkS3VideoM3U8 = getEnv('REACT_APP_VIDEO_URL_M3U8');
export const linkCloudFront = getEnv('REACT_APP_IMAGE_CLOUD_FRONT_URL');

// export function convertParams(query) {
//   if (!query) return '';
//   let params = query?.split('&');
//   let query_string = {};
//   for (let i = 0; i < params?.length; i++) {
//     let pair = params[i]?.split('=');
//     let key = decodeURIComponent(pair.shift());
//     let value = decodeURIComponent(pair.join('='));
//     if (typeof query_string[key] === 'undefined') {
//       query_string[key] = value;
//     } else if (typeof query_string[key] === 'string') {
//       let arr = [query_string[key], value];
//       query_string[key] = arr;
//     } else {
//       query_string[key].push(value);
//     }
//   }
//   return query_string;
// }
export const customStyles = {
  control: (base, state) => ({
    ...base,
    width: '100%',
    maxHeight: 60,
    overflow: 'auto',
    border: state.isFocused ? '1px solid #E5E6EB !important' : '1px solid #E5E6EB !important',
    boxShadow: state.isFocused
      ? '1px solid #b1b6bb !important'
      : '1px solid #b1b6bb !important',
  }),
  menu: (base, state) => ({
    ...base,
    width: '100%',
    border: 'none !important',
    borderRadius: '6px',
    overflow: 'hidden',
    wordBreak: 'break-word',
    zIndex: 51,
  }),
  option: (base, state) => {
    return {
      ...base,
      backgroundColor: state.isSelected ? '#FFDBDB' : 'transparent',
      color: '#282727',
      minHeight: 40,
      '&:hover': {
        backgroundColor: '#FFDBDB',
        color: '#282727',
      },
      '&.content-right': {
        color: 'red',
      },
      svg: {
        color: 'red',
        display: state.isSelected ? 'block' : 'none',
      },
      fontWeight: state.isSelected ? 500 : 400,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'relative',
    };
  },
  placeholder: (base) => ({
    ...base,
    fontWeight: 400,
    fontSize: 14,
    color: '#B1b6bb',
    position: 'absolute',
    padding: '2px 2px 2px 0',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    return {
      ...provided,
      opacity,
      fontWeight: 500,
      fontSize: 14,
      position: 'absolute',
      padding: '2px 2px 2px 0',
      color: '#282727',
    };
  },
  menuList: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
    wordBreak: 'break-word',
  }),
  valueContainer: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
    height: 44,
    minHeight: 44,
  }),
  multiValue: (base) => ({
    ...base,
    alignItems: 'center',
  }),
  input: (base) => ({
    ...base,
    height: 44,
    padding: 0,
    margin: 0,
    position: 'relative',
  }),
  dropdownIndicator: (base) => ({
    ...base,
    display: 'none',
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
};

export const customStylesReselect = {
  ...customStyles,
  singleValue: (base) => ({
    ...base,
    fontWeight: 500,
    fontSize: 14,
    position: 'absolute',
    padding: '2px 20px 2px 0',
    color: '#1a1a1a',
  }),
  dropdownIndicator: (base) => ({
    ...base,
    display: 'block',
  }),
  control: (base, state) => {
    return {
      ...base,
      width: '100%',
      svg: {
        color: '#666565',
        transform: state.menuIsOpen ? 'rotate(180deg)' : '',
      },
      borderRadius: '6px',
      boxShadow: 'none',
      borderColor: state.isFocused ? '#1480FF' : '#E5E6EB',
    };
  },
  menu: (base, state) => ({
    ...base,
    border: 'none !important',
    borderRadius: '8px',
    overflow: 'hidden',
    zIndex: 10,
    width: 213,
    boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.06)',
  }),
  option: (base, state) => {
    return {
      ...base,
      position: 'relative',
      backgroundColor: state.isSelected ? '#FFDBDB' : 'transparent',
      color: '#1A1A1A',
      '&.content-right': {
        color: '#DC143C',
      },
      svg: {
        color: '#DC143C',
        display: state.isSelected ? 'block' : 'none',
      },
      fontWeight: state.isSelected ? 500 : 400,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '12px 19px 12px 16px',
      gap: '5px',
    };
  },
};

export const customStylesHeight32 = {
  control: (base, state) => ({
    ...base,
    width: 148,
    height: 32,
    minHeight: 32,
    maxHeight: 32,
    overflow: 'hidden',
    border: state.isFocused ? '1px solid #b1b6bb !important' : '1px solid #b1b6bb !important',
    boxShadow: state.isFocused
      ? '1px solid #b1b6bb !important'
      : '1px solid #b1b6bb !important',
  }),
  menu: (base, state) => ({
    ...base,
    width: 148,
    border: 'none !important',
    borderRadius: '6px',
    overflow: 'hidden',
    wordBreak: 'break-word',
    zIndex: 10,
  }),
  option: (base, state) => {
    return {
      ...base,
      backgroundColor: state.isSelected ? '#FFDBDB' : 'transparent',
      color: '#282727',
      minHeight: 40,
      '&:hover': {
        backgroundColor: '#FFDBDB',
        color: '#282727',
      },
      '&.content-right': {
        color: 'red',
      },
      svg: {
        color: 'red',
        display: state.isSelected ? 'block' : 'none',
      },
      fontWeight: state.isSelected ? 500 : 400,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'relative',
    };
  },
  placeholder: (base) => ({
    ...base,
    fontWeight: 400,
    fontSize: 14,
    color: '#B1b6bb',
    position: 'absolute',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    return {
      ...provided,
      opacity,
      fontWeight: 500,
      fontSize: 14,
      position: 'absolute',
      color: '#282727',
    };
  },
  menuList: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
    wordBreak: 'break-word',
  }),
  valueContainer: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
    height: 32,
    minHeight: 32,
    maxHeight: 32,
    display: 'flex',
  }),
  multiValue: (base) => ({
    ...base,
    alignItems: 'center',
  }),
  input: (base) => ({
    ...base,
    height: 32,
    minHeight: 32,
    maxHeight: 32,
    padding: 0,
    margin: 0,
    position: 'relative',
  }),
  dropdownIndicator: (base) => ({
    ...base,
    display: 'none',
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
};

export const listRule = [
  {
    id: 1,
    name: 'Admin',
  },
  {
    id: 2,
    name: 'Creator',
  },
  {
    id: 3,
    name: 'Fan',
  },
  {
    id: 4,
    name: 'Agency',
  },
  {
    id: 5,
    name: 'Affiliator',
  },
  {
    id: 6,
    name: 'CS',
  },
];

export const getAge = (dateString) => {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const checkIsLocal = () => {
  return window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
    ? true
    : false;
};

export const msToTime = (duration) => {
  var minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  return hours + ':' + minutes || '00:00';
};

export const TimeRemain = (ms = 0) => {
  const [timeRemaining, settimeRemaining] = useState(ms);
  const [hours, sethours] = useState(0);
  const [mins, setmins] = useState(0);
  // countdown timer
  useEffect(() => {
    if (timeRemaining < 0) return;
    const intervalId = setInterval(() => {
      settimeRemaining(timeRemaining - 1000 * 60);
      setmins(Math.floor((timeRemaining / 1000 / 60) % 60));
      sethours(Math.floor((timeRemaining / 1000 / 60 / 60) % 24));
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeRemaining]);
  return `${hours}:${mins}`;
};
export function isFileImage(file) {
  return file && file['type'].split('/')[0] === 'image';
}

export const patternNoFirstSpacing = /^[^\s]/;
export const patternNoFirstSpacingNumber = /^[^\s]^[^0-9]+$/;
export const patternNoNumber = /[^0-9]/;
export const patternRequiredStringAndNumber = /^(?=.*?\d)(?=.*?[a-zA-Z])[a-zA-Z\d]+$/;
export const patternKatakanaHiraganaFullWidth = /^[ぁ-んァ-ン一-龥]/;
export const patternEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const patternNameJp = /^[一-龯]/gu;
export const patternNameRomaji = /^[A-Za-z.-]+(\s*[A-Za-z.-]+)*$/;

export const patternNameJpFullWidth = /^[Ａ-Ｚａ-ｚぁ-んァ-ンー-龥]+$|^$/;
export const patternFullWidthJp = /[ぁ-んァ-ンー-龥]/;
export const patterTextJp =
  /[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f]/;
export const patternOnlyNumber = /^[0-9]+$/;
export const patternCharacterLatinh = /^[A-Za-z0-9_ ]+$/;
export const patternCharacterLatinhUnderLineAndDot = /^[A-Za-z0-9_.]+$/;
export const patternCharacterLatinhNoSpace = /^[A-Za-z0-9]+$/;
export const patternNameJpFullWidthHalfWidth = /^[Ａ-Ｚａ-ｚぁ-んァ-ンー-龥ｧ-ﾝﾞﾟ]+$|^$/;
export const patternSplitCard = /(\d{4}(?!\s))/g;
export const patternHyperLink = /(https?:\/\/[^\s]+)/g;

export const selectOptionTimeLiveCreate = [
  {
    value: '00:30',
    label: `30${i18n.t('Creator.CreateLiveStream.minutes')}`,
  },
  { value: '01:00', label: `1 ${i18n.t('Creator.CreateLiveStream.time')}` },
  {
    value: '01:30',
    label: `1 ${i18n.t('Creator.CreateLiveStream.time')}${
      i18n.language === 'en' ? ' and ' : ''
    }30${i18n.t('Creator.CreateLiveStream.minutes')}`,
  },
  {
    value: '02:00',
    label: `2 ${i18n.t('Creator.CreateLiveStream.time')}${i18n.language === 'en' ? 's' : ''}`,
  },
];

export const TYPE_VIDEOS = ['video/mp4', 'video/x-ms-wmv', 'video/quicktime'];
export const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/jpg', 'imgage/heic'];
export const acceptedImgVideoTypes = [...acceptedImageTypes, ...TYPE_VIDEOS];
export const acceptedVideoTypes = ['video/*'];
export const acceptedFileZipTypes = ['zip', 'application/zip', '.zip'];
export const acceptedFilePDFTypes = ['application/pdf', 'image/jpeg', 'image/png', 'image/jpg'];
export const acceptedFilePDFContractTypes = ['application/pdf'];
export const ONE_GB = 1073741824;
export const TEN_GB = 10737418240;
export const FIVE_GB = 5368709120;
export const TEN_MB = 10485760;
export const FIVE_MB = 5242880;

export const isValidDate = (date) => {
  return date && Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date);
};
//- Thông báo khi file upload > 1GB
export const failFileThan1Gb = (id = '') => {
  return pushNotify(
    'error',
    i18n.t('ValidateMsg.videoThan1Gb2'),
    `${Date.now() + '_' + id + '_1'}`
  );
};
//- Thông báo khi file upload > 10GB
export const failFileThan10Gb = (id = '') => {
  return pushNotify(
    'error',
    i18n.t('ValidateMsg.videoThan10Gb2'),
    `${Date.now() + '_' + id + '_1'}`
  );
};
//- Thông báo khi file upload > 5GB
export const failFileThan5Gb = (id = '') => {
  return pushNotify(
    'error',
    i18n.t('ValidateMsg.videoThan5Gb2'),
    `${Date.now() + '_' + id + '_1'}`
  );
};
//- Thông báo khi upload nhiều hơn 10 file ản
export const failImgThan10 = (id = '') => {
  return pushNotify(
    'error',
    i18n.t('ValidateMsg.uploadThan10Img'),
    `${Date.now() + '_' + id + '_2'}`
  );
};
//- Thông báo khi upload nhiều hơn 4 file ảnh
export const failImgThan4 = (id = '') => {
  return pushNotify(
    'error',
    i18n.t('ValidateMsg.uploadThan4Img'),
    `${Date.now() + '_' + id + '_77'}`
  );
};
//- Thông báo khi upload nhiều hơn 1 file video
export const failVideoThan1 = (id = '') => {
  return pushNotify(
    'error',
    i18n.t('ValidateMsg.uploadThan1Video'),
    `${Date.now() + '_' + id + '_3'}`
  );
};
//- Thông báo khi upload video ít hơn 3 phút
export const failVideoLonger3Minutes = (id = '') => {
  return pushNotify(
    'error',
    i18n.t('ValidateMsg.VIDEO_LONGER_3_MINUTES'),
    `${Date.now() + '_' + id + '_3'}`
  );
};
//thông báo khi file sai định dạng
export const failFileInvalid = (id = '') => {
  return pushNotify(
    'error',
    i18n.t('ValidateMsg.ZiP_OR_VIDEO_IS_REQUIRED'),
    `${Date.now() + '_' + id + '_4'}`
  );
};
//- Thông báo khi upload nhiều hơn 1 file zip
export const failZipThan1 = (id = '') => {
  return pushNotify(
    'error',
    i18n.t('ValidateMsg.uploadThan1Zip'),
    `${Date.now() + '_' + id + '_7'}`
  );
};
export const failImgInvalid = (id = '') => {
  return pushNotify(
    'error',
    i18n.t('ValidateMsg.typeImg1'),
    `${Date.now() + '_' + id + '_9'}`
  );
};
export const failVideoInvalid = (id = '') => {
  return pushNotify(
    'error',
    i18n.t('ValidateMsg.typeVideo1'),
    `${Date.now() + '_' + id + '_10'}`
  );
};
export const failImgThan10Mb = (id = '') => {
  return pushNotify(
    'error',
    i18n.t('ValidateMsg.imgProfileMaxSize'),
    `${Date.now() + '_' + id + '_11'}`
  );
};

//- Thông báo khi upload video > 60s
export const failShortVideo = (id = '') => {
  return pushNotify(
    'error',
    i18n.t('ValidateMsg.max_duration_short_video'),
    `${Date.now() + '_' + id + '_12'}`
  );
};

//- Thông báo khi upload video > 60s
export const failMoreThan1Video = (id = '') => {
  return pushNotify('error', `${i18n.t('ValidateMsg.uploadThan1Video')}`);
};

//- Thông báo khi chỉ upload 1 video or 4 ảnh
export const failMoreThan1Video1Img = (id = '') => {
  return pushNotify('error', `${i18n.t('ValidateMsg.ImgOrVideoRequired')}`);
};

// thông báo 24h checkbox C152

export const notifi24hCheckbox = (id = '', check) => {
  return pushNotify(
    'success',
    check
      ? i18n.t('Creator.PostArticle.postShort.check24h')
      : i18n.t('Creator.PostArticle.postShort.noCheck24h'),
    `${Date.now() + '_' + id + '_13'}`
  );
};

export const uploadImgVideoErrorS3 = (id = '') => {
  return pushNotify('error', i18n.t('S3 error'), `${Date.now() + '_' + id + '_14'}`);
};
export const countOcurrences = (str, value) => {
  var regExp = new RegExp(value, 'gi');
  return (str?.toString()?.match(regExp) || []).length;
};

export const convertToCurrentTime = (dayConvert, format = 'YYYY/MM/DD HH:mm') => {
  const moment = require('moment-timezone');
  const currentZone = moment.tz.guess();
  const day = moment.utc(dayConvert);
  return dayConvert ? day.tz(currentZone).format(format) : '';
};

export const currentTimeToUTC = (dayConvert, format = 'YYYY/MM/DD HH:mm') => {
  return dayConvert ? moment.utc(dayConvert).format(format) : '';
};

export const formatDateBE = (dayConvert) => {
  return dayConvert ? dayConvert?.split(' ')[0]?.split('-')?.join('/') : '';
};

export const formatHourMinuteBE = (dayConvert) => {
  return dayConvert ? dayConvert.split(' ')[1].split(':').slice(0, 2).join(':') : '';
};

export const GENDER = [
  { value: 1, label: i18n.t('Common.men') },
  { value: 0, label: i18n.t('Common.woman') },
];

export const MONEY_TYPE = [
  { value: 1, label: i18n.t('User.inforBank.ordinaryDeposit') },
  { value: 2, label: i18n.t('User.inforBank.currentAccount') },
];

export const LIST_TAB = [
  { id: 1, label: i18n.t('Search.tabKeyword') },
  { id: 2, label: i18n.t('Search.tabGenre') },
];
export const LIST_TAB_CHILD = [
  { id: 1, label: i18n.t('Common.account') },
  { id: 2, label: i18n.t('Common.post') },
];

export const LIST_TAB_NOTIFICATION = [
  { id: 1, label: i18n.t('Common.operation'), type: 'admin' },
  { id: 2, label: i18n.t('Common.community'), type: 'activity' },
];

export const DATA_NAVBAR_AF004 = [
  { title: i18n.t('Common.sales_history') },
  { title: i18n.t('Affiliator.AF004.salesSummary') },
];

export const getPlaceholderSearch = (type) => {
  switch (type) {
    case 1:
      return i18n.t('Search.placeholderKeyword');
    case 2:
      return i18n.t('Search.placeholderGenre');
    default:
      return i18n.t('Search.placeholderKeyword');
  }
};

export const LIST_TAB_LIVESTREAM = [
  { id: 1, label: i18n.t('MenuSiderbar.duringDelivery'), tab_navigate_list_live: 1 },
  { id: 2, label: i18n.t('MenuSiderbar.deliverySchedule'), tab_navigate_list_live: 2 },
];

export const LIST_TAB_BROWSER_HISTORY = [
  { id: 0, text: i18n.t('BrowsingHistory.tabLeft') },
  { id: 1, text: i18n.t('BrowsingHistory.tabRight') },
];

export const OPTION_SORT_RANKING_BUYER = [
  {
    value: 'desc',
    label: i18n.t('Creator.RankingPaymentList.descendingOrder'),
  },
  {
    value: 'asc',
    label: i18n.t('Creator.RankingPaymentList.ascendingOrder'),
  },
];

export const acceptIntegerOfInputText = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '0',
  'Backspace',
  'Delete',
  'Tab',
];

export const convertFullToHalf = (str = '') =>
  str?.toString()?.replace(/[！-～]/g, (r) => String.fromCharCode(r.charCodeAt(0) - 0xfee0));

export const formatTimezone = () => {
  const date = new Date();
  let char = date.getTimezoneOffset() > 0 ? '-' : '+';
  let offset = Math.abs(date.getTimezoneOffset());
  let hour = offset / 60;
  return `${hour !== 0 ? char : ''}${hour < 10 ? `0${hour}` : hour}:00`;
};

export const onErrImage = (e, avatar = true, img_admin = false, live = false) => {
  e.target.onerror = null;
  if (live === 'live-mini') {
    e.target.src = LOGO_COCO_FANS;
  } else if (live === 'live-big') {
    e.target.src = NO_IMAGE_LIVESTREAM;
    e.target.style.background = 'transparent';
  } else {
    e.target.src = avatar ? AVATAR_DEFAULT : IMG_DEMO;
  }
  e.target.classList.add('img-default');
  if (img_admin) e.target.style.objectFit = 'contain';
  if (live === 'live-mini') {
    e.target.style.objectFit = 'contain';
    e.target.style.backgroundColor = '#eee';
  }
};

export const onErrImagePost = (e) => {
  e.target.onerror = null;
  e.target.src = IMG_NO_WATER_MARK_MOBILE;
};

export const isEmptyObject = (obj) => {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
};

//* ITEM DISPLAY / 1 PAGE IN POPUP TOP RANKING F014 & C092
export const itemPerPages = 10;

export const formatNumber = (number = 0) => {
  if (number === 0) return 0;
  else if (number < 10000) {
    return number;
  } else if (number >= 10000 && number < 1000000000) {
    const x = Math.floor(number / 10000);
    let y = Math.floor(number % 10000);
    if (y > 0) {
      y = y / 10000;
      return `${x}.${y.toFixed(1).substring(2)}${i18n.t('Common.tenThousand')}`;
    }
    return `${x}${i18n.t('Common.tenThousand')}`;
  } else {
    const x = Number(Math.floor(number / 1000000000));
    let y = Math.floor(number % 1000000000);
    if (y > 0) {
      y = y / 1000000000;
      return `${x}.${y.toFixed(1).substring(2)}${i18n.t('Common.tenThousand')}`;
    }
    return `${x}${i18n.t('Common.bilion')}`;
  }
};

export const isAdmin = Storage.has(STORAGE.IS_ADMIN) || false;
export const isAgency = Storage.has(STORAGE.IS_AGENCY) || false;
export const userIdToAdmin = Storage.get(STORAGE.USER_ID);

export const queryString = (params) =>
  Object.keys(params)
    .map((key) => (params[key] ? `${key}=${params[key]}` : ''))
    .filter((s) => Boolean(s))
    .join('&');

export const checkMaxLengthInputNumber = (id) => {
  const element = document.getElementById(id);
  element.value = element.value.replace(/[^0-9]/gi, '');
};

export const autoFormatPhone = (phone = '') => {
  const cleaned = ('' + phone).replace(/\D/g, '');
  const match10 = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);
  const match11 = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
  const match12 = cleaned.match(/^(\d{4})(\d{4})(\d{4})$/);

  let newPhone = '';
  if (match10) {
    newPhone = match10[1] + '-' + match10[2] + '-' + match10[3];
  }

  if (match11) {
    newPhone = match11[1] + '-' + match11[2] + '-' + match11[3];
  }

  if (match12) {
    if (match12[1] === '0120') {
      newPhone = '0120-' + match12[2] + '-' + match12[3];
    } else {
      newPhone = match12[1] + '-' + match12[2] + '-' + match12[3];
    }
  }
  return newPhone;
};

export const disableSpaceKeyCode = (e) => {
  const { keyCode, code } = e;
  if (+keyCode === 32 || code === 'Space') {
    return e.preventDefault();
  }
};

export const queryParamsTimezone = (key = 'timezone') =>
  `&${key + '=' + encodeURIComponent(formatTimezone())}`;

export const getMiliSecondConvert = (time) => moment(convertToCurrentTime(time)).valueOf();
export const getDateConvert = (date) => moment(convertToCurrentTime(date)).date();
export const getCurrentMiliSecond = moment().valueOf();

export const getTitleByTypeAdmin = (type, title = '', bonus) => {
  switch (+type) {
    case 8:
      return i18n.t('Notification.typeNotifi.admin.8');
    case 12:
      return i18n.t('Notification.typeNotifi.12');
    case 13:
      return i18n.t('Notification.typeNotifi.13');
    case 14:
      return i18n.t('Notification.typeNotifi.14');
    case 15:
      return i18n.t('Notification.typeNotifi.15');
    case 16:
      return i18n.t('Notification.typeNotifi.16');
    case 17:
      return i18n.t('Notification.typeNotifi.17');
    case 18:
      return i18n.t('Notification.typeNotifi.18');
    case 19:
      return i18n.t('Notification.typeNotifi.19');
    case 20:
      return i18n.t('Notification.typeNotifi.20');
    case 21:
      return i18n.t('Notification.typeNotifi.21');
    case 28:
      return i18n.t('Notification.typeNotifi.28');
    case 29:
      return i18n.t('Notification.typeNotifi.29', { bonus: bonus ? bonus : 10 });
    default:
      return title;
  }
};

export const getTitleByType = (type, name, isIdol, titlePackage = '', postType) => {
  switch (type) {
    case 0:
      return i18n.t('Notification.typeNotifi.0');
    case 1:
      return i18n.t('Notification.typeNotifi.1');
    case 2:
      return i18n.t('Notification.typeNotifi.2', { name: name });
    case 3:
      return `${name}${i18n.t('Notification.typeNotifi.3')}`;
    case 4:
      return isIdol
        ? `${name}${i18n.t('Notification.typeNotifi.4Idol')}`
        : i18n.t('Notification.typeNotifi.4');
    case 5:
      return `${name}${i18n.t('Notification.typeNotifi.5')}`;
    case 6:
      return `${name}${i18n.t('Notification.typeNotifi.6')}`;
    case 7:
      return i18n.t('Notification.typeNotifi.7');
    case 8:
      return i18n.t('Notification.typeNotifi.8', { name: name });
    // case 9:
    //   return `${i18n.t('Notification.typeNotifi.9_1')}${name}${i18n.t(
    //     'Notification.typeNotifi.9_2'
    //   )}`;
    case 9:
      if (postType === 2) {
        return `${name}${i18n.t('Notification.typeNotifi.9_4')}`;
      } else {
        return `${name}${i18n.t('Notification.typeNotifi.9_3')}`;
      }
    case 10:
      return i18n.t('Notification.typeNotifi.10');
    case 11:
      return i18n.t('Notification.typeNotifi.11');
    case 12:
      return i18n.t('Notification.typeNotifi.12');
    case 13:
      return i18n.t('Notification.typeNotifi.13');
    case 14:
      return i18n.t('Notification.typeNotifi.14');
    case 15:
      return i18n.t('Notification.typeNotifi.15');
    case 16:
      return i18n.t('Notification.typeNotifi.16');
    case 17:
      return i18n.t('Notification.typeNotifi.17');
    case 18:
      return i18n.t('Notification.typeNotifi.18');
    case 19:
      return i18n.t('Notification.typeNotifi.19');
    case 20:
      return i18n.t('Notification.typeNotifi.20');
    case 21:
      return i18n.t('Notification.typeNotifi.21');
    case 26:
      return i18n.t('Notification.typeNotifi.26', { planName: titlePackage });
    case 27:
      return i18n.t('Notification.typeNotifi.27', { planName: titlePackage });
    default:
      return ``;
  }
};

export const checkNameAccountDraf = (account_name) => {
  if (account_name?.includes('draf_')) {
    const checkName = account_name.split('_');
    return checkName[2];
  } else return account_name;
};

export const urlSharePost = (id) => getEnv('REACT_APP_FAN_URL') + '/post/' + id;
export const urlShareLive = (id) => getEnv('REACT_APP_FAN_URL') + '/lives/' + id;

export const IMG_NO_WATER_MARK =
  i18n.language === 'jp' ? img_no_water_mark : img_no_water_mark_en;
export const IMG_NO_WATER_MARK_MOBILE =
  i18n.language === 'jp' ? img_no_water_mark_mobile : img_no_water_mark_mobile_en;

export const IMG_NO_WATER_MARK_MOBILE_3_COLUMN =
  i18n.language === 'jp'
    ? img_no_water_mark_mobile_3_column
    : img_no_water_mark_mobile_3_column_en;

export const IMG_NO_WATER_MARK_MOBILE_2_COLUMN_SHORT_VIDEO =
  i18n.language === 'jp'
    ? img_no_water_mark_mobile_2_column_short_video
    : img_no_water_mark_mobile_2_column_short_video_en;

export const IMG_NO_WATER_MARK_MOBILE_2_COLUMN_POST_CONTENT =
  i18n.language === 'jp'
    ? img_no_water_mark_mobile_2_column_post_content
    : img_no_water_mark_mobile_2_column_post_content_en;

export const REPORT_TYPE_ENUM = {
  revenue: 'revenue',
  reactions: 'reactions',
  subscription: 'subscription',
  content: 'content',
  livestream: 'livestream',
};

export const AGE_ENUM = {
  // age_10_19: `10${i18n.t('Common.generation')}`,
  age_20_29: `20${i18n.t('Common.generation')}`,
  age_30_39: `30${i18n.t('Common.generation')}`,
  age_40_49: `40${i18n.t('Common.generation')}`,
  age_50_59: `50${i18n.t('Common.generation')}`,
  age_over_60: `60${i18n.t('Common.generation')}`,
};

export const AGE_ENUM_ARR = [
  // { key: 'age_10_19', value: '10代' },
  { key: 'age_20_29', value: `20${i18n.t('Common.generation')}` },
  { key: 'age_30_39', value: `30${i18n.t('Common.generation')}` },
  { key: 'age_40_49', value: `40${i18n.t('Common.generation')}` },
  { key: 'age_50_59', value: `50${i18n.t('Common.generation')}` },
  { key: 'age_over_60', value: `60${i18n.t('Common.generation')}` },
];

export const GENDER_ENUM = {
  female: i18n.t('Common.woman'),
  male: i18n.t('Common.men'),
};

export const USER_REACTIONS_ENUM = {
  number_content: i18n.t('Report.number_content'),
  number_like: i18n.t('Report.number_like'),
  number_bookmark: i18n.t('Report.number_bookmark'),
  number_follow: i18n.t('homePageHeader.follower'),
};

export const REVENUE_ENUM = {
  subscription_revenue: i18n.t('Common.subscription'),
  content_revenue: i18n.t('Common.content'),
  livestream_revenue: i18n.t('Common.live_streaming'),
  other_revenue: i18n.t('Report.other_tips'),
  total: i18n.t('Sales.total_sales'),
};

export const GET_TAB_VALUE_SEARCH = (value) => {
  switch (value) {
    case 1:
      return 'post';
    case 2:
      return 'creator';
    case 3:
      return 'category';
    default:
      return 'post';
  }
};

export const checkNavigateByRole = (role, status) => {
  // role ? idol : fan
  if (role) {
    // if (status === 4 && window.location.pathname !== `${newPathUser}profile/info`)
    //   window.location.href = `${newPathUser}profile/info`;
    if (
      ROLE_ACCOUNT_BANNED.includes(status) &&
      window.location.pathname !== `${newPathUser}`
    ) {
      // if (status !== 6) {
      //   window.location.href = `${newPathUser}`;
      // } else if (window.location.pathname !== `${newPathUser}profile/update`) {
      //   window.location.href = `${newPathUser}`;
      // }
      // if (window.location.pathname !== `${newPathUser}profile/update`) {
      //   window.location.href = `${newPathUser}`;
      // }
    }
  } else {
    if (ROLE_ACCOUNT_BANNED.includes(status) && window.location.pathname !== '/my-page')
      window.location.href = '/my-page';
  }
};

export const renderTimeAfter24h = (time) => {
  const dateAfter24h = new Date(time);
  return moment(dateAfter24h.setTime(dateAfter24h.getTime() + 86400000)).format(
    'YYYY/MM/DD HH:mm:ss'
  );
};

export const reloadBrowserParent = () => {
  window.close();
  window.onunload = refreshParent;
  function refreshParent() {
    window.opener.location.reload();
  }
};

export const validateTimePostNormalRequired = (style) => (
  <div
    style={{
      color: '#ff0000',
      marginBottom: 16,
      position: 'absolute',
      bottom: -26,
      ...style,
    }}>
    {i18n.t('ValidateMsg.itemRequired')}
  </div>
);

export const textValidateRequired2 = (style) => (
  <div
    className='error-message-date error-message-custom'
    style={{
      color: '#ff0000',
      ...style,
    }}>
    {i18n.t('ValidateMsg.itemRequired')}
  </div>
);

export const queryLanguage = i18n.language === 'en' ? 'en' : 'ja';

export const convertTextMonthEN = (month) => {
  switch (+month) {
    case 1:
      return i18n.t('Common.jan');
    case 2:
      return i18n.t('Common.feb');
    case 3:
      return i18n.t('Common.mar');
    case 4:
      return i18n.t('Common.apr');
    case 5:
      return i18n.t('Common.may');
    case 6:
      return i18n.t('Common.jun');
    case 7:
      return i18n.t('Common.jul');
    case 8:
      return i18n.t('Common.aug');
    case 9:
      return i18n.t('Common.sep');
    case 10:
      return i18n.t('Common.oct');
    case 11:
      return i18n.t('Common.nov');
    case 12:
      return i18n.t('Common.dec');
    default:
      break;
  }
};

const langueJP = i18n.language === 'jp';
export const selectTimeRegister = [
  { value: 1, label: `1${langueJP ? '' : ' '}${i18n.t('Creator.Home.month')}` },
  { value: 2, label: `2${langueJP ? '' : ' '}${i18n.t('Creator.Home.months')}` },
  { value: 3, label: `3${langueJP ? '' : ' '}${i18n.t('Creator.Home.months')}` },
  { value: 4, label: `4${langueJP ? '' : ' '}${i18n.t('Creator.Home.months')}` },
  { value: 5, label: `5${langueJP ? '' : ' '}${i18n.t('Creator.Home.months')}` },
  { value: 6, label: `6${langueJP ? '' : ' '}${i18n.t('Creator.Home.months')}` },
  { value: 7, label: `7${langueJP ? '' : ' '}${i18n.t('Creator.Home.months')}` },
  { value: 8, label: `8${langueJP ? '' : ' '}${i18n.t('Creator.Home.months')}` },
  { value: 9, label: `9${langueJP ? '' : ' '}${i18n.t('Creator.Home.months')}` },
  { value: 10, label: `10${langueJP ? '' : ' '}${i18n.t('Creator.Home.months')}` },
  { value: 11, label: `11${langueJP ? '' : ' '}${i18n.t('Creator.Home.months')}` },
  { value: 12, label: `12${langueJP ? '' : ' '}${i18n.t('Creator.Home.months')}` },
];

export const CustomOption = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      {children}
      <CheckedIcon />
    </components.Option>
  );
};

export const CustomDropdownIndicator = ({ children, ...props }) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowLeftIcon />
    </components.DropdownIndicator>
  );
};

export const maxLengthInputTypeNumber = (e) => {
  if (e.target.value.length > e.target.maxLength)
    e.target.value = e.target.value.slice(0, e.target.maxLength);
};

export const getQueryParams = (search, param) => new URLSearchParams(search).get(param);

export const getVideoDuration = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const media = new Audio(reader.result);
      media.onloadedmetadata = () => resolve(media.duration);
    };
    reader.readAsDataURL(file);
    reader.onerror = (error) => reject(error);
  });

export const getDurationVideo = (file, funcGetDuration) =>
  (() => {
    window.URL = window.URL || window.webkitURL;
    var video = document.createElement('video');
    video.preload = 'metadata';
    video.src = URL.createObjectURL(file);

    video.onloadedmetadata = function () {
      window.URL.revokeObjectURL(video.src);
      funcGetDuration && funcGetDuration(video.duration);
    };

    video.onerror = (error) => {
      console.log(error);
    };
  })();

export const checkTypeVideoErr = (e) => {
  const TYPE_VIDEO_ERR = ['video/mov', 'video/wmv', 'video/quicktime', 'video/x-quicktime'];
  return (
    e.target.files[0]['type'].includes('video') &&
    !TYPE_VIDEO_ERR?.includes(e.target.files[0]['type'])
  );
};

export const getFileNameAndExtensionMiniURL = (url) => {
  // The regex to match file name and extension
  const regex = /^(?<fileWithoutExtension>.+)\.(?<fileExtension>.+)$/i;

  const match = url.match(regex);

  if (match) {
    // filename with extension
    const fileName = `${match?.groups?.fileWithoutExtension}mnw32.${match?.groups?.fileExtension}`;
    return fileName;
  } else {
    // No match found
    console.log('No file name or extension found');
  }
};

export const decryptPath = function (pathEncrypted, isMini) {
  if (!pathEncrypted) return;
  const decodeImgKey = getEnv('REACT_APP_KEY_DECODE_IMAGE');
  // Laravel creates a JSON to store iv, value and a mac and base64 encodes it.
  // So let's base64 decode the string to get them.
  try {
    pathEncrypted = JSON.parse(atob(pathEncrypted));
    // Decrypt the value, providing the IV.
    const decrypted = CryptoJS.AES.decrypt(
      pathEncrypted.value,
      CryptoJS.enc.Base64.parse(decodeImgKey),
      {
        iv: CryptoJS.enc.Base64.parse(pathEncrypted.iv),
      }
    );
    const url = decrypted.toString(CryptoJS.enc.Utf8);
    return isMini ? getFileNameAndExtensionMiniURL(url) : url;
  } catch (e) {
    return pathEncrypted;
  }
};

export const setOverflowTablet = function () {
  if (isTablet && isIOS && widthSreen < 1024) {
    document.getElementById('root').style.overflow = 'unset';
  } else {
    document.getElementById('root').style.overflow = 'auto';
  }
};

export const funcThumbnailIMG = (url) => {
  if (typeof url !== 'string') {
    return ''; // Return empty string if url is not a string
  }

  const match = url?.match(/_(\w+)\./);
  const match1 = url?.match(/\/([^/]+)\/([^/]+)\.m3u8$/);
  if (match || match1) {
    return `${linkCloudFront}thumbnails/image_${match1 ? match1[2] : match[1]}.png`;
  } else {
    return '';
  }
};

export const updateFirstItemValue = (array, newValue) => {
  if (array.length > 0) {
    array[0].is_pinned = newValue;
  }
  return array;
};

export const moveIdToFront = (array, id) => {
  // Find the object with the specified ID
  const result = updateFirstItemValue(array, 0);
  const foundObject = result.find((obj) => obj.id === id);
  foundObject.is_pinned = 1;

  if (foundObject) {
    // Remove the found object from the array
    const index = array.indexOf(foundObject);
    array.splice(index, 1);

    // Push the found object to the front of the array
    array.unshift(foundObject);
  }
  return array;
};

export const convertPhone = (valuePhone) => {
  let phone = valuePhone || '';
  const cleaned = ('' + phone).replace(/\D/g, '');
  const match10 = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);

  if (match10) {
    phone = match10[1] + '-' + match10[2] + '-' + match10[3];
  }

  const match11 = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
  if (match11) {
    phone = match11[1] + '-' + match11[2] + '-' + match11[3];
  }

  const match12 = cleaned.match(/^(\d{4})(\d{4})(\d{4})$/);
  if (match12) {
    phone = match12[1] + '-' + match12[2] + '-' + match12[3];
  }
  return phone;
};

export const checkLinkPostSale = (url) => {
  const linkHyper = url?.match(patternHyperLink);
  if (linkHyper && linkHyper?.length) {
    const postIdMatch = linkHyper[0]?.match(/\/post\/(\d+)/);
    const id = postIdMatch ? postIdMatch[1] : null;
    return id;
  }
};

export const sortListPackagePointAndDate = (data) => {
  if (data && data?.length > 0) {
    let dataSortCreate = data.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
    let dataSortPoint = dataSortCreate.sort((a, b) => a.price - b.price);
    return dataSortPoint;
  }
  return [];
};

export const decodeToken = async (jwt, secret = secretKeyCard) => {
  try {
    const secretKey = new TextEncoder().encode(secret);
    const { payload } = await jwtVerify(jwt, secretKey);
    return payload;
  } catch (error) {}
  return null;
};
