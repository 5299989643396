import ProgressiveImage from 'react-progressive-graceful-image';
import { decryptPath, onErrImage } from 'utils';
import ImgDemo from 'images/ImagePlacehoder.png';
import LoadingIconV2 from 'images/LoadingIconV2';
import AvatarPlaceholder from 'images/avatarPlaceholder.png';

import './index.scss';
const ImgCustomWithLoading = ({
  src = '',
  alt = 'image',
  isPlaceholderAvatar = false,
  className,
  avatarErr = true,
  isAdmin = false,
  live = false,
  screen = '',
  noIMGPlaceholder = false,
  ...props
}) => {
  src = decryptPath(src);
  switch (screen) {
    case 'avartar_navbar_60_60':
      src = `${src}?width=60&height=60`;
      break;
    case 'home_avartar_100_100':
      src = `${src}?width=100&height=100`;
      break;
    case 'home_banner_720_200':
      src = `${src}?width=720&height=auto`;
      break;
    case 'home_img_short_video_hight_light_72_72':
      src = `${src}?width=72&height=72`;
      break;
    case 'home_3_img_default_243_280':
      src = `${src}?width=243&height=280`;
      break;
    case 'home_img_post_640_418':
      src = `${src}?width=640&height=418`;
      break;
    case 'recommend_top_56_56':
      src = `${src}?width=56&height=56`;
      break;
    case 'recommend_sale_footer_290_180':
      src = `${src}?width=290&height=180`;
      break;
    case 'edit_profile_3_img_328_274':
      src = `${src}?width=328&height=274`;
      break;
    case 'timeline_flow_top_140_180':
      src = `${src}?width=140&height=180`;
      break;
    case 'timeline_auto_375_pc':
      src = `${src}?width=auto&height=375`;
      break;
    case 'post_775_720_pc':
      src = `${src}?width=775&height=auto`;
      break;
    case 'post_640_auto_pc':
      src = `${src}?width=640&height=auto`;
      break;
    case 'post_240_auto_pc':
      src = `${src}?width=240&height=auto`;
      break;
    case 'post_319_auto_pc':
      src = `${src}?width=319&height=auto`;
      break;
    case 'avatar_profile_160_160':
      src = `${src}?width=160&height=160`;
      break;
    case '3_img_profile_210_241':
      src = `${src}?width=210&height=241`;
      break;
    case 'avatar_44_44':
      src = `${src}?width=44&height=44`;
      break;
    case 'img_highline_190_248':
      src = `${src}?width=190&height=248`;
      break;
    case 'popup_fan_detail_70_70':
      src = `${src}?width=70&height=70`;
      break;
    case 'avatar_notification_list_60_60':
      src = `${src}?width=60&height=60`;
      break;
    case 'avatar_mini_28_28':
      src = `${src}?width=28&height=28`;
      break;
    case 'image_preview_223_223':
      src = `${src}?width=223&height=223`;
      break;
    default:
      break;
  }

  return (
    <ProgressiveImage src={src} placeholder={''}>
      {(src, loading) => {
        return (
          <div>
            {loading ? (
              <LoadingIconV2 className='spinner-icon' />
            ) : (
              <img
                src={src}
                alt={alt}
                className={`img-custom ${className}`}
                onError={(e) => onErrImage(e, avatarErr, isAdmin, live)}
                {...props}
              />
            )}
          </div>
        );
      }}
    </ProgressiveImage>
  );
};
export default ImgCustomWithLoading;
